define('base/koBindings',
    [
        'jquery',
        'knockout',
        'underscore',
        'base/config',
        'durandal/composition',
        'i18next',
        'base/callbacks'
    ],
    function ($, ko, _, config, composition, i18n, callbacks) {
        "use strict";

        var indexedStates = _.indexBy(config.states, "name");

        // fileinput button (init after attached to DOM)
        //---------------------------
        composition.addBindingHandler("fileinputbutton", {
            init: function (element, valueAccessor) {
                if (typeof $(element).attr('data-bfi-disabled') !== 'undefined' || !$(element).is("input[type='file']") || $(element).closest(".file-input-wrapper").length) {
                    return;
                }
                var extraClasses = "", style = "",
                    form = $(element).closest("form");

                if (typeof $(element).attr('class') !== 'undefined') {
                    extraClasses = $(element).attr('class');
                }
                if (typeof $(element).attr('style') !== 'undefined') {
                    style = $(element).attr('style');
                }
                var input = $('<div>').append($(element).eq(0).clone()).html();
                $(element).replaceWith('<i class="file-input-wrapper fa fa-plus-square ' + extraClasses + '" style="' + style + '">' + input + '</i>');
                form.on("change", "input[type=file]", function (event) {
                    var arr = [], observable = valueAccessor();
                    $.each(this.files, function (index, item) {
                        arr.push({
                            filename: item.name,
                            size: item.size,
                            type: item.type,
                            lastModified: item.lastModified
                        });
                    });
                    observable(arr);
                });
                $(element).mousemove(function (cursor) {
                    var input, wrapper, wrapperX, wrapperY, inputWidth, inputHeight, cursorX, cursorY, moveInputX, moveInputY;
                    wrapper = $(this);
                    input = wrapper.find("input");
                    wrapperX = wrapper.offset().left;
                    wrapperY = wrapper.offset().top;
                    inputWidth = input.width();
                    inputHeight = input.height();
                    cursorX = cursor.pageX;
                    cursorY = cursor.pageY;
                    moveInputX = cursorX - wrapperX - inputWidth + 20;
                    moveInputY = cursorY - wrapperY - (inputHeight / 2);
                    input.css({
                        left: moveInputX,
                        top: moveInputY
                    });
                });
            }
        });


        function removeFixedHeader($container, $el) {
            $container.removeAttr("style");
            if ($el.is("table.hidden-header")) {
                $el.removeClass("hidden-header");
                $el.parent().find(".fixed-header").off("click", "th[data-sort]").remove();
                $el.parent().replaceWith($el);
                $el.find("th").each(function () {
                    $(this).css("width", "auto");
                });
            }
            return true;
        }               

        function addFixedHeader($container, $el, offsetTop) {
            var mustRefresh = false;
            /*
             $container.css({
             position: "absolute",
             bottom: 0,
             left: 0,
             right: 0,
             top: offsetTop + "px",
             overflowY: "auto",
             marginTop: "-1px"
             });*/

            if ($el.is("table")) {
                var $fh = $("<table>").addClass($el.attr('class')).css({
                    width: $el.outerWidth()
                });

                $el.find("th").each(function () {
                    if (!$(this).outerWidth()) {
                        mustRefresh = true;
                    }
                    $(this).css("width", $(this).outerWidth());
                });
                $fh.append($el.find("thead").clone());
                $el.parent().append(
                    $("<div>")
                        .append($fh.addClass("fixed-header"))
                        .append($el.addClass("hidden-header"))
                );
                $fh.on("click", "th[data-sort]", function () {
                    $el.find("th[data-sort='" + $(this).data("sort") + "']").click();
                });
            }
            return mustRefresh;
        }               
        
        composition.addBindingHandler("fixedHeaderTable", {
            init: function (element, valueAccessor) {
                var mustRefresh = true, maxRefresh = 20,
                    opts = $.extend({
                        container: "DIV",
                        topOffset: ($(".app-header").clientHeight) * -1
                    }, ko.unwrap(valueAccessor())),
                    $el = $(element),
                    $container = $(element).closest(opts.container),
                    offsetTop = $container.offset().top + opts.topOffset,

                    refresh = function () {
                        removeFixedHeader($container, $el);
                        addFixedHeader($container, $el, offsetTop);
                    };                                                                               
                    
                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    $(window).off("resize", refresh);
                    callbacks.tableDataLoaded.remove(refresh);
                    removeFixedHeader($container, $el);
                });
                mustRefresh = addFixedHeader($container, $el, offsetTop);
                $(window).on("resize", refresh);                
                callbacks.tableDataLoaded.add(refresh);
                if (mustRefresh) {
                    setTimeout(refresh, 500);
                }

            }
        });

        ko.bindingHandlers.sortableTable = {
            init: function (element, valueAccessor) {
                var $el = $(element),
                    sortedBy = "",
                    sortedDesc = false,
                    toggle = $("<small>"),//.addClass("pull-right"),
                    none = $("<i>").addClass("fa fa-sort"),
                    up = $("<i>").addClass("fa fa-sort-up text-info"),
                    down = $("<i>").addClass("fa fa-sort-down text-info");

                if ($el.is("table")) {
                    $el.find("th[data-sort]").each(function () {
                        $(this).prepend(toggle.clone().append(none.clone()), "&nbsp;");
                    });
                    $el.on("click", "th[data-sort]", function () {
                        var sortName = $(this).data("sort");
                        if (sortedBy === sortName) {
                            sortedDesc = !sortedDesc;
                        } else {
                            sortedBy = sortName;
                            sortedDesc = false;
                        }
                                                                        
                        $("th[data-sort] i.fa")
                            .removeClass("fa-sort-up fa-sort-down text-info")
                            .addClass("fa-sort");
                        $("th[data-sort='" + sortName + "'] i.fa")
                            .removeClass("fa-sort")
                            .addClass(sortedDesc ? "fa-sort-up" : "fa-sort-down")
                            .addClass("text-info");
                                            
                        callbacks.sortClicked.fire({ order : sortName, asc : sortedDesc ? "0" : "1"});
                    });
                }
            }
        };

        // date from timestamp
        // --------------------------
        ko.bindingHandlers.date = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                var valueUnwrapped = ko.unwrap(value);
                if (valueUnwrapped) {
                    $(element).text(new Date(valueUnwrapped).toLocaleDateString());
                }
            }
        };

        ko.bindingHandlers.time = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                var valueUnwrapped = ko.unwrap(value);
                if (valueUnwrapped) {
                    $(element).text(new Date(valueUnwrapped).toLocaleTimeString());
                }
            }
        };

        ko.bindingHandlers.i18n = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                var valueUnwrapped = ko.unwrap(value);
                if (valueUnwrapped) {
                    $(element).text(i18n.t(value));
                }
            }
        };


        ko.bindingHandlers.i18nStatus = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                var valueUnwrapped = ko.unwrap(value);
                if (valueUnwrapped) {
                    $(element).text(i18n.t("app:status." + valueUnwrapped.toLowerCase()));
                }
            }
        };

        ko.bindingHandlers.i18nStatusLabel = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                var valueUnwrapped = ko.unwrap(value);
                if (valueUnwrapped) {
                    $(element).addClass("label state-" + valueUnwrapped.toLowerCase());
                    $(element).text(i18n.t("app:status." + valueUnwrapped.toLowerCase()));
                }
            }
        };

        ko.bindingHandlers.i18nParameter = {
            init: function (element, valueAccessor) {
                var value = valueAccessor();
                var valueUnwrapped = ko.unwrap(value);
                if (valueUnwrapped) {
                    $(element).text(i18n.t("app:parameters." + valueUnwrapped.toLowerCase()));
                }
            }
        };

        ko.bindingHandlers.starRated = {
            update: function (element, valueAccessor) {
                var value = ko.unwrap(valueAccessor());
                ko.bindingHandlers.css.update(element, function () {
                    return {
                        'rated-1': String(value) === "1",
                        'rated-2': String(value) === "2",
                        'rated-3': String(value) === "3",
                        'rated-4': String(value) === "4",
                        'rated-5': String(value) === "5"
                    };
                });
            }
        };

        // escape
        //---------------------------
        ko.bindingHandlers.escape = {
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                var command = valueAccessor();
                $(element).keyup(function (event) {
                    if (event.keyCode === 27) { // <ESC>
                        command.call(viewModel, viewModel, event);
                    }
                });
            }
        };

        // hidden
        //---------------------------
        ko.bindingHandlers.hidden = {
            update: function (element, valueAccessor) {
                var value = ko.unwrap(valueAccessor());
                ko.bindingHandlers.visible.update(element, function () {
                    return !value;
                });
            }
        };

        ko.bindingHandlers.inline = {
            init: function (element, valueAccessor) {
                var text = $(element);
                var input = $('<input />', {'type': 'text', 'style': 'display:none', 'class': 'form-control'});
                text.after(input);

                ko.applyBindingsToNode(input.get(0), {value: valueAccessor()});
                ko.applyBindingsToNode(text.get(0), {text: valueAccessor()});

                text.click(function () {
                    //input.width(span.width());
                    text.hide();
                    input.show();
                    input.focus();
                });

                input.blur(function () {
                    text.show();
                    input.hide();
                });

                input.keypress(function (e) {
                    if (e.keyCode === 13) {
                        text.show();
                        input.hide();
                    }
                });
            }
        };
               
        

    });

