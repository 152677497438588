define("contexts/session",
    [
        "knockout",
        "plugins/dialog",
        "jquery",
        "i18next",
        "base/store",
        "dataservices/dataservice",
        "base/presenter",
        "plugins/router",
        "viewmodels/modals/login",
        "base/callbacks"
    ],
    function (ko, dialog, $, i18n, store, dataservice, presenter, router, LoginModal, callbacks) {
        "use strict";

        var module = {
            info: ko.observable({}),
            config: ko.observable({}),
            token: dataservice.token
        };

        var STORE_SESSION = "SRS-SESSION";

        var storeToken = function (token) {
            dataservice.token(token);
            if (token) {
                store.save(STORE_SESSION, {token: token});
            } else {
                store.clear(STORE_SESSION);
            }
            return module;
        };

        module.hasAdminAccess = ko.computed(function () {
            return module.token() && module.info() && module.info().userRole === "admin";
        }, this);

        module.hasIssuerAccess = ko.computed(function () {
            return module.token() && module.info() && module.info().userRole === "issuer";
        }, this);

        module.hasEditorAccess = ko.computed(function () {
            return module.token() && module.info() && module.info().userRole === "editor";
        }, this);
        
        module.hasFilterSource = ko.computed(function () {
        	return module.token() && module.info() && module.info().source !== null && module.info().source !== "";
        }, this);
        
        module.showIssueReport = ko.computed(function () {
        	return module.token() && module.info() && module.info().issueReport;
        }, this)

        module.hasEditorAccessToIssueById = function( id ) {
           var sessInfo = ko.unwrap(module.info);
           if( sessInfo.hasOwnProperty('supplierIds')
                                && $.isArray(sessInfo.supplierIds)) {
                for( var i = 0; i < sessInfo.supplierIds.length; i++) {
                    if(sessInfo.supplierIds[i] === id) {
                        return true;
                    }
                }
           }
           return false;
        };

        module.hasAccess = ko.computed(function () {
            return module.token() && module.info() && module.info().userRole ? true : false;
        }, this);

        module.navigationLinks = ko.computed(function () {
            var navs = [];
            if (!module.hasAccess()) {
                return navs;
            }
            
            //console.log( "Report: " + module.info().issueReport );
            
            navs.push({
                title: "app:titles.issues",
                hash: "#issues",
                image: "fa fa-ticket"
            });

            if (module.hasAdminAccess() && !module.hasFilterSource()) {
                navs.push({
                    title: "app:titles.users",
                    hash: "#users",
                    image: "fa fa-users"
                });
            }

            if (!module.hasFilterSource() && (module.hasAdminAccess() || module.hasIssuerAccess())) {
                navs.push({
                    title: "app:titles.import",
                    hash: "#import",
                    image: "fa fa-download"
                });
            }

            if (module.hasAdminAccess() || module.hasEditorAccess()) {
                navs.push({
                    title: "app:titles.reports",
                    hash: "#reports",
                    image: "fa fa-print"
                });
            }
            return navs;
        }, this);

        var readUserInfo = function () {
            return dataservice.user.getCurrentUser()
                .then(function (userdata) {
                    module.info(userdata);   
                    
                    callbacks.userLoaded.fire(userdata);
                    if ( !userdata.lastLogin && !userdata.registerDate ) {                        
                                                                        
                        var options = [{text: i18n.t("app:labels.ok"), value: "ok"}];
                        var message = i18n.t("app:messages.migration_info1");
                        if( userdata.hasOwnProperty('supplierIds')
                                && $.isArray( userdata.supplierIds )
                                    && userdata.supplierIds.length > 1 ) {
                            message = i18n.t("app:messages.migration_info2");
                        }

                        dialog.showMessage(message,
                                           i18n.t("app:titles.migration_info"), options, false, {"class":"appMessageBox attentionBorder modal-content modal-content-xs"})
                                    .then(function() {
                                        callbacks.showSettings.fire(true);
                                     });
                    }
                    
                    presenter.toast(store.fetch(store.KEYS.MESSAGE_AFTER_RELOAD) 
                                        || "app:services.login_success", presenter.LEVEL.SUCCESS);  
                                                                         
                    store.clear(store.KEYS.MESSAGE_AFTER_RELOAD);                  
                    return module.readConfiguration();
                }).fail(function (msg) {
                    storeToken("").info({});
                    //presenter.toast("app:services.login_failed", presenter.LEVEL.DANGER, msg);
                    return false;
                });
        };

        module.loginByAuthKey = function( authKey )  {
            return dataservice.user.login( null, null, authKey )
                    .then( function(data) {
                        storeToken(data.responseMessage || "");
                        return true;
                    }).fail(function (data) {
                        presenter.toast(data.responseMessage || "ERROR", presenter.LEVEL.DANGER);
                        return false;
                    });
        };

        module.requireAccess = function () {
            // console.log("session.requireAccess : ENTERING");
            if (module.hasAccess()) {
                // console.log("session.requireAccess -> hasAccess = true");
                return true;
            }
            // console.log("session.requireAccess -> LoginModal.show");
            return LoginModal.show()
                .then(function (result) {
                    if (result && result.token) {
                        storeToken(result.token || "");
                        return readUserInfo(result.username);
                    }
                    return false;
                })
                .fail(function () {
                    return false;
                });
        };

        module.logoff = function () {
            storeToken("").info({});
            router.navigate("/");
        };

        module.readConfiguration = function () {
            return dataservice.config.getConfig()
                .then(function (configdata) {
                	// console.log("..... " + configdata);
                    module.config(configdata);
                    //presenter.toast("app:services.config_success", presenter.LEVEL.SUCCESS);
                    return true;
                }).fail(function (msg) {
                    presenter.toast("app:services.config_failed", presenter.LEVEL.DANGER, msg);
                    return false;
                });
        };

        module.autoLogin = function () {
            var savedSession = store.fetch(STORE_SESSION);
            // console.log( "session.autoLogin savedSession: " + (  savedSession ? ( savedSession + " " + savedSession.token ) : "''" ) );
            if (!savedSession || !savedSession.token) {
                return $.when(false);
            }
            dataservice.token(savedSession.token);
            // console.log( "session.autoLogin -> readUserInfo" );
            return readUserInfo();
        };

        module.reloadPage = function( lang, message_after_realod ) {
            // console.log("session.reloadPage( lang: " + lang + ", message_after_reload: " + message_after_realod + ")");
            if( lang ) {
                store.save( store.KEYS.USER_LANGUAGE, lang );
            }
            if( message_after_realod ) {
                store.save( store.KEYS.MESSAGE_AFTER_RELOAD, message_after_realod );
            }

            store.save( store.KEYS.NAVIGATE_AFTER_RELOAD, window.location.hash );
            window.location.hash = "#RELOAD" + new Date().getTime();
            window.location.reload();
        };

        module.saveToken = function( token ) {
            storeToken(token);
        };

        return module;
    });
