define(
    'viewmodels/modals/filter_states',[
        "plugins/dialog",
        "knockout",
        "jquery",
        "base/forms",
        "base/config",
        "i18next"
    ],
    function (dialog, ko, $, forms, config, i18n) {
        "use strict";

        var FilterStatesModal = function (params) {
            var self = this;

            self.possibleStates = config.states;
            self.selectedStates = ko.observableArray(params.filter || []);

            self.cancel = function () {
                dialog.close(self, null);
            };

            self.ok = function () {
                dialog.close(self, {filter: self.selectedStates()});
            };
            
            self.compositionComplete = function() {
               $('.modal-content').css({top:'70px'});
            };
        };

        FilterStatesModal.show = function (params) {
            return dialog.show(new FilterStatesModal(params));
        };

        return FilterStatesModal;
    });
