define('viewmodels/parts/pageheader',[],
    function () {
        "use strict";

        return function () {
            console.log("pageheader needs a pagination model as viewmodel");
        };

    });

