define('contexts/supplier',
    [
        'knockout',
        'underscore',
        'base/store',
        'dataservices/dataservice',
        'base/presenter'
    ],
    function (ko, _, store, dataservice, presenter) {
        "use strict";

        var module = {};

        module.getSupplierById = function(id) {
            return dataservice.supplier.getSupplierById(id)
                .then(function(data) {
                    return data;
                })
                .fail(function(msg) {
                    presenter.toast("app:services.get_suppliers_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getSupplierByNumber = function(number) {
            return dataservice.supplier.getSupplierByNumber(number)
                .then(function(data) {
                    return data;
                })
                .fail(function(msg) {
                    presenter.toast("app:services.get_suppliers_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getSuppliers = function () {
            return dataservice.supplier.getSuppliers()
                .then(function(data) {
                    data.resultsPage = $.map(data.resultsPage, ko.mapper.fromJS);
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_suppliers_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getSupplierUsers = function (supplierId) {
            return dataservice.supplier.getSupplierUsers(supplierId)
                .then(function(data) {
                    data.resultsPage = $.map(data.resultsPage, function(item) {
                        item.displayName = item.firstName + " " + item.lastName;
                        if (item.department) {
                            item.displayName += " (" + item.department + ")";
                        }
                        return item;
                    });
                    data.resultsPage = $.map(data.resultsPage, ko.mapper.fromJS);
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_supplier_user_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };


        return module;
    });

