define(
    'viewmodels/index',[
        "jquery",
        "plugins/router",
        "contexts/context",
        "base/callbacks"
    ],
    function ($, router, context, callbacks) {
        "use strict";

        var module = {};
        
        module.navigate = function() {
            callbacks.navigateAfterLogin.fire();
        };

        module.activate = function () {
            // console.log("index.activate");
            
            if (context.session.hasAccess()) {
                // console.log("index.activate -> hasAccess");
                module.navigate();
            } else {
                context.session.requireAccess()
                    .then(function (hasAccess) {
                        // console.log("index.activate -> hasAccess: " + hasAccess);
                        if (hasAccess) {
                            module.navigate();
                        }
                    });
            }
            return true;
        };

        return module;
    });
