define('base/store',
    [
        'amplify',
        'base/config'
    ],
    function (amplify, config) {
        "use strict";

        var module = {};
        
        module.KEYS = {
           NAVIGATE_AFTER_RELOAD : "navigate.after.reload",           
           USER_LANGUAGE : "user.language",
           MESSAGE_AFTER_RELOAD : "message.after.reload"
        };

        module.clear = function (key) {
            return amplify.store(key, null);
        };

        module.fetch = function (key) {
            return amplify.store(key);
        };

        module.save = function (key, value) {
            amplify.store(key, value, {expires: config.storeExpirationMs});
        };

        return module;
    });
