/**
 * the root viewmodel (referenced with $root by data-binding)
 */
define(
    'viewmodels/shell',[
        "jquery",
        "knockout",
        "plugins/router",
        "i18next",
        "contexts/context",
        "base/presenter",
        'viewmodels/modals/modal',
        'base/store',
        'base/callbacks'
    ],
    function ($, ko, router, i18n, context, presenter, modal, store, callbacks) {
        "use strict";

        var module = {};

        module.router = router;
        module.session = context.session;
        module.version = $("body").data("version") || "NO VERSION";
        module.blockUi = presenter.isBlocking;

        module.pageControl = {
            page: context.pageControl
        };

        module.showUserSettings = function(showAccountChange) {
            module.navbarClicked();
            var username = context.session.info().userName;
            context.admin.getUserByName(username)
                .then(function (user) {
                    return modal.UserModal.show(user, true, null, showAccountChange === true);
                });
        };

        module.checkUserLang = function( user ) {
            // load User to check language from User
            var lang = user.lang;
            var lastLang = store.fetch(store.KEYS.USER_LANGUAGE);

            if( lang && lang !== lastLang ) {
                context.session.reloadPage(lang);
            }
        };

        module.compositionComplete = function () {
            presenter.shellAttached();
            module.revalidateSizeLater();
        };

        module.revalidateSize = function() {

            $('#appheader').css('width', '' + ( $('#content').width() ) + 'px' );

            var mobileMenu = $('#iconmenu').is(':visible');
            if( mobileMenu ) {
                $('#navbarsrs').addClass('navbar-as-popup');
            } else {
                $('#navbarsrs').removeClass('navbar-as-popup');

            }

            var max = 0;
            $('#pageControls').find('*').not('#filterSelect *')
                    .each(function(index, elem) {
                if( max < $(elem).offset().top + $(elem).height() ) {
                    max = $(elem).offset().top + $(elem).height();
                }
            });

            if( module.session.hasAccess() ) {
                if( max < 90 ) {
                    max = 90;
                }
            } else {
                if( max < 50 ) {
                    max = 50;
                }
            }

            if( max !== 50 && max !== 90 ) {
                max = max - $(window).scrollTop();
            }

            $('#content').css('margin-top', '' + ( max + 5 ) + 'px' );
            $('#appheader').css('height', '' + ( max + 5 ) + 'px' );

            callbacks.layoutChange.fire( { mobile : mobileMenu } );
        };

        module.navbarClicked = function() {
            if( $('#iconmenu').is(':visible') ) {
                $('#navbarsrs').collapse('hide');
            }
        };

        module.revalidateSizeLater = function () {
            window.setTimeout(function() {
                module.revalidateSize();
            }, 200 );
        };

        module.initMobileToggle = function() {
            window.addEventListener('resize', module.revalidateSizeLater);
            router.on('router:navigation:complete', module.revalidateSizeLater);
            $(document).click(function (event) {
                module.navbarClicked();
            });
        };

        module.userLoaded = function( user ) {
             module.checkUserLang( user );
        };        

        module.currentUserName = ko.computed(function () {
            var info = context.session.info();
            return (info.firstName || "") + " " + (info.lastName || "");
        }, this);

        module.activeHash = ko.computed(function () {
            var ai = router.activeInstruction();
            if (!ai) {
                return "#";
            }
            return ai.config.hash;
        }, this);

        module.isTargetHash = function( hash ) {
            if( hash
                  && $.trim( hash ).length > 0
                     && $.trim(hash) !== '#'
                       && $.trim(hash) !== '/'
                         && hash.indexOf('RELOAD') === -1 ) {
               return true;
            }
            return false;
        };

        module.replaceUrl = function( path ) {
            //console.log("replaceUrl( '" + path + "' )" );
            var redirectUrl = location.protocol
                                    + "//" + location.host
                                    + location.pathname
                                    //+ "?r="+new Date().getTime()
                                    +"#" + path;
            window.location.replace(redirectUrl);
        };

        module.navigateAfterLogin = function( path ) {
            //console.log("shell.navigateAfterLogin path:" + path );
            if( path ) {
                module.replaceUrl(path);
            } else {
                var navTarget = store.fetch(store.KEYS.NAVIGATE_AFTER_RELOAD);
                //console.log("shell.navigateAfterLogin -> navTarget: '" + navTarget + "'" );
                if( module.isTargetHash( navTarget ) ) {
                    router.navigate(navTarget);
                } else
                if( !module.isTargetHash( location.hash ) ) {
                    // console.log("shell.navigateAfterLogin -> router.navigate('issues')");
                    router.navigate( "issues");
                } else {
                    // console.log("shell.navigateAfterLogin -> NOTHING TO DO");
                }
            }

            setTimeout(function () {
                    // console.log("clear local storage key: " + store.KEYS.NAVIGATE_AFTER_RELOAD );
                    store.clear(store.KEYS.NAVIGATE_AFTER_RELOAD);
                }, 3000 );
        };

        module.autoLogin = function( path ) {
            // console.log("shell.autoLogin path: " + path);
            return context.session.autoLogin()
                .always(function () {
                    router.activate();
                    //console.log("shell.autoLogin - allways path: " + path
                    //        + " hasAccess() = " + context.session.hasAccess() );
                    if( context.session.hasAccess() ) {
                        module.navigateAfterLogin( path );
                    }
                });
        };

        module.navigateToRootReplacingUrl = function() {
            window.setTimeout(function() {
                module.replaceUrl("");
            }, 3000);
        };

        module.checkSessionAndUrlParams = function() {
            var params = location.search;
            // console.log("#1 shell.checkSessionAndUrlParams params: " + params);
            if(params && params.charAt(0) === '?' ) {
               params = params.substring(1);
            }
            //console.log("#2 shell.checkSessionAndUrlParams params: " + params);
            var query = router.parseQueryString(params);
            if( query && query.authKey ) {
                //console.log("#3 shell.checkSessionAndUrlParams query.authKey: " + query.authKey);
                context.session.loginByAuthKey( query.authKey )
                   .then( function() {
                        module.autoLogin( query.path || 'issues' );
                    })
                    .fail( module.navigateToRootReplacingUrl );
            } else
            if ( query && query.token ) {
                //console.log("#4 shell.checkSessionAndUrlParams query.token: " + query.token);
                context.session.saveToken(query.token);
                module.autoLogin( query.path || 'issues' )
                    .fail( module.navigateToRootReplacingUrl );
            } else {
                //console.log("#5 shell.checkSessionAndUrlParams autoLogin");
                module.autoLogin();
            }
        };

        module.initCallbacks = function() {
            callbacks.userLoaded.add(module.userLoaded);
            callbacks.showSettings.add(module.showUserSettings);
            callbacks.navigateAfterLogin.add(module.navigateAfterLogin);
        };
        module.initCallbacks();

        module.activate = function () {
            router.map([
                {
                    route: "",
                    title: i18n.t("app:titles.srs"),
                    moduleId: "viewmodels/index",
                    nav: false
                },
                {
                    route: ["issues"],
                    title: i18n.t("app:titles.issues"),
                    moduleId: "viewmodels/issues",
                    nav: true,
                    image: "fa fa-ticket"
                },
                {
                    route: "issues/new",
                    title: i18n.t("app:titles.new_issue"),
                    moduleId: "viewmodels/new_issue",
                    nav: false
                },
                {
                    route: "issues/:id",
                    title: i18n.t("app:titles.issue"),
                    moduleId: "viewmodels/issue",
                    nav: false
                },
                {
                    route: "users(/:username)",
                    title: i18n.t("app:titles.users"),
                    moduleId: "viewmodels/users",
                    nav: true, // todo check if admin
                    image: "fa fa-users"
                },
                {
                    route: "register",
                    moduleId: "viewmodels/register",
                    title: i18n.t("app:titles.register"),
                    nav: false
                },
                {
                    route: "import",
                    moduleId: "viewmodels/import",
                    title: i18n.t("app:titles.import"),
                    nav: true, // todo check if admin
                    image: "fa fa-download"
                },
                {
                    route: "reports",
                    moduleId: "viewmodels/reports",
                    title: i18n.t("app:titles.reports"),
                    nav: true, // todo check if admin
                    image: "fa fa-print"
                },
                {
                    route: "passwordreset",
                    moduleId: "viewmodels/passwordreset",
                    title: i18n.t("app:titles.passwordreset"),
                    nav: false
                }
            ]).buildNavigationModel().mapUnknownRoutes("viewmodels/index", "");

            module.checkSessionAndUrlParams();
            module.initMobileToggle();
            return true;
        };

        return module;
    }
);
