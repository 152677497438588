define(
    'viewmodels/reports',[
        'jquery',
        'knockout',
        'moment',
        'contexts/context',
        'base/config',
        'i18next',
        'base/sort',
        'underscore'
    ],
    function ($, ko, moment, context, config, i18n, sortings, _) {
        "use strict";

        var module = {};

        module.selectedStartDate = ko.observable(moment().subtract(14, 'days').format("L")).extend({
            validate: {name: "selectedStartDate", type: "datepicker", checks: [""]}
        });
        module.selectedEndDate = ko.observable(moment().add(1, 'days').format("L")).extend({
            validate: {name: "selectedEndDate", type: "datepicker", checks: [""]}
        });

        module.allStates = ko.observableArray(_.map(config.states, function (item) {
            return {
                id: item.id,
                name: i18n.t("app:status." + item.name)
            }
        }));
        module.allClassifications = ko.observableArray([]);
        module.allIssueTypes = ko.observableArray([]);
        module.allIssueObjects = ko.observableArray([]);
        module.allSuppliers = ko.observableArray([]);

        module.selectedReportType = ko.observable("issues");
        module.selectedStates = ko.observableArray([0, 1, 2, 3, 4, 5, 6, 7]);
        module.selectedClassifications = ko.observableArray([]);
        module.selectedIssueTypes = ko.observableArray([]);
        module.selectedIssueObjects = ko.observableArray([]);
        module.selectedSuppliers = ko.observableArray([]);

		function createReportFile(format) {
        	var urlBase = "report",
                params = {
                    token: context.session.token(),
                    format: format,
                    classificationId: (module.selectedClassifications() || []).join(",") || null,
                    issueObjectId: (module.selectedIssueObjects() || []).join(",") || null,
                    issueTypeId: (module.selectedIssueTypes() || []).join(",") || null,
                    supplierId: (module.selectedSuppliers() || []).join(",") || null,
                    createdFrom: moment(module.selectedStartDate(), "L").format("YYYY-MM-DD"),
                    createdTo: moment(module.selectedEndDate(), "L").format("YYYY-MM-DD"),
                    lang: config.locale
                }; 
            if (module.selectedReportType() === 'issues') {
                urlBase += "/issues";
                params.status = (module.selectedStates() || []).join(",") || null;
            } else if (module.selectedReportType() === 'errorquality') {
            	urlBase += "/lapses";
            	params.status = (module.selectedStates() || []).join(",") || null;
            }
            else {
                urlBase += "/benchmark";
            }
           
		    var form = document.createElement("form");
		  	form.setAttribute("method", "post");
		  	form.setAttribute("action", urlBase);
		  	form.setAttribute("target","_blank");  
		  	for (var i in params) {
		    	if (params.hasOwnProperty(i)) {
			      var input = document.createElement('input');
			      input.type = 'hidden';
			      input.name = i;
			      input.value = params[i];
			      form.appendChild(input);
		    	}
		  	}              
		  	document.body.appendChild(form);  
		 	form.submit();                 
		  	document.body.removeChild(form);
        }

        module.reportPdf = function () {
			createReportFile('pdf');
        };
        module.reportHtml = function () {
			createReportFile('html');
        };
        module.reportCsv = function () {
			createReportFile('csv');
        };

        module.canActivate = function () {        	
            return context.session.requireAccess()
                    && ( context.session.hasAdminAccess()
                         || context.session.hasEditorAccess() );
        };
        
        module.showQualityReport = ko.computed( function() {
            var session = context.session;
            
            return session.hasAccess() &&
                    session.hasAdminAccess() &&
                    !session.hasFilterSource();
        }, this );
        
        module.showIssueReport = ko.computed( function() {
        	var session = context.session;
        	return session.hasAccess() && 
        			session.hasAdminAccess() && 
        			session.showIssueReport();
        }, this);
        
        module.showSuccessBtn = ko.computed( function() {
        	return module.showQualityReport() || module.showIssueReport();
        }, this);
        
        module.showCSVButton = ko.computed(function() {
            var rt = module.selectedReportType();
            return rt === 'issues' || rt === 'errorquality';
        }, this);

        module.activate = function () {
            context.supplier.getSuppliers()
                .then(function (suppliers) {
                    module.allSuppliers((suppliers.resultsPage || []));
                });
            context.businessenum.getClassifications()
                .then(function (classifications) {
					
					for (var k = 0; k < classifications.length; k++) {
						
						if (!classifications[k].parentId) {
							classifications[k].name = i18n.t("app:issueclassifications." + classifications[k].id );
							
							if (classifications[k].name.indexOf("app:") > -1) {
								classifications[k].name = "";
							}
						}
					}
					
                    module.allClassifications((classifications || []).sort(sortings.byNameAsc));
                });
            context.businessenum.getIssueObjects()
                .then(function (issueObjects) {
					
					for (var k = 0; k < issueObjects.length; k++) {
						
						if (!issueObjects[k].parentId) {
							issueObjects[k].name = i18n.t("app:issueobjects." + issueObjects[k].id );
						}
					}
					
                    module.allIssueObjects((issueObjects || []).sort(sortings.byNameAsc));
                });
            context.businessenum.getIssueTypes()
                .then(function (issueTypes) {
					
					for (var k = 0; k < issueTypes.length; k++) {
						issueTypes[k].name = i18n.t("app:issuetypes." + issueTypes[k].id );
					}
					
                    module.allIssueTypes((issueTypes || []).sort(sortings.byNameAsc));
                });

            return true;
        };

        return module;
    });
