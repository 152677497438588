define('models/user',
    [
        'underscore'
    ],
    function (_) {
        "use strict";

        function Issue() {
            this.id = 0;
            this.isNil = false;
            return this;
        }

        Issue.prototype.update = function (params) {
            var self = this;
            _.mapObject(params, function (val, key) {
                if (self.hasOwnProperty(key) && !_.isFunction(self[key])) {
                    self[key] = val;
                }
            });
            return self;
        };

        Issue.fromDto = function (params) {
            return new Issue().update(params || {});
        };

        Issue.Nil = new Issue().update({isNil: true});

        return Issue;
    });


define("models/issue", function(){});

