define('viewmodels/modals/modal',
    [
        'viewmodels/modals/issues_select',
        'viewmodels/modals/message_create',
        'viewmodels/modals/login',
        'viewmodels/modals/user_edit',
        'viewmodels/modals/filter_states',
        'viewmodels/modals/confirm_dialog',
        'viewmodels/modals/translate_info'
    ],
    function (IssuesModal, MessageModal, LoginModal, UserModal, FilterStatesModal, 
              ConfirmModal, TranslateInfoModal) {
        "use strict";

        var module = {
            FilterStatesModal: FilterStatesModal,
            IssuesModal: IssuesModal,
            LoginModal: LoginModal,
            MessageModal: MessageModal,
            UserModal: UserModal,
            ConfirmModal : ConfirmModal,
            TranslateInfoModal: TranslateInfoModal
        };

        return module;
    });
