define(
        'viewmodels/modals/confirm_dialog',[
            "plugins/dialog",
            "knockout",
            "base/forms",
            "contexts/context",
            "i18next"
        ],
        function (dialog, ko, forms, context, i18n) {
            "use strict";

            var ConfirmModal = function (params) {
                var self = this;

                self.title = params.title || "";

                self.editMessageForm = {
                    message: ko.observable(params.message || ""),
                    password: ko.observable().extend({
                        validate: {name: "password", type: "password", rows: 5, checks: ["required"]}
                    })
                };

                self.cancel = function () {
                    dialog.close(self, null);
                };
            };

            ConfirmModal.prototype.ok = function () {
                var self = this;
                if (forms.checkForm(this.editMessageForm, true)) {
                    dialog.close(self, self.editMessageForm);
                }
            };

            ConfirmModal.show = function (params) {
                return dialog.show(new ConfirmModal(params));
            };

            return ConfirmModal;
        });
