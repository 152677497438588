define('contexts/user',
        [
            'knockout',
            'base/store',
            'dataservices/dataservice',
            'base/presenter'
        ],
        function (ko, store, dataservice, presenter) {
            "use strict";

            var module = {};

            module.register = function (registerData) {
                return dataservice.user.register({
                    userName: registerData.username,
                    firstName: registerData.firstname,
                    lastName: registerData.lastname,
                    email: registerData.email,
                    phone: registerData.phone,
                    department: registerData.department,
                    password: registerData.password,
                    emailLanguage: registerData.emailLanguage
                }).then(function (data) {
					if (data.userName) {
						presenter.toast("app:services.register_success", presenter.LEVEL.SUCCESS);
                    	return data;
					}
					
                    presenter.toast("app:services.register_failed", presenter.LEVEL.DANGER);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.register_failed", presenter.LEVEL.DANGER);
                    return msg;
                });
            };

            module.editorUsers = function (issue) {
                return dataservice.user.getEditors(issue.supplierId).then(function (data) {
                    //presenter.toast("editorUsers.sucess ", presenter.LEVEL.SUCCESS,"supplierId = " + issue.supplierId);                     
                    return $.map(data, ko.mapper.fromJS);
                }).fail(function (msg) {
                    presenter.toast("app:services.editorUsers.failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
            };

            module.getAllUsers = function () {
                // return dataservice.user.getAllUsers()
                return dataservice.user.getAllUsers()
                        .then(function (data) {
                            presenter.toast("app:services.editorUsers.success", presenter.LEVEL.SUCCESS);
                            data.resultsPage = $.map(data.resultsPage, ko.mapper.fromJS);
                            return data;
                        }).fail(function (msg) {
                    presenter.toast("app:services.editorUsers.failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
            };


            module.resetPassword = function (username) {
                return dataservice.user.resetPassword(username)
                        .then(function (data) {
                            presenter.toast("app:services.passwordreset_success", presenter.LEVEL.SUCCESS);
                            return data;
                        }).fail(function (msg) {
                    presenter.toast("app:services.passwordreset_failed", presenter.LEVEL.DANGER);
                    return msg;
                });
            };

            module.newPassword = function (token, username, password) {
                return dataservice.user.newPassword(token, username, password)
                        .then(function (data) {
                            presenter.toast("app:services.newpassword_success", presenter.LEVEL.SUCCESS);
                            return data;
                        }).fail(function (msg) {
                    presenter.toast("app:services.newpassword_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
            };

            module.save = function (userData, ownSettings, showAccountChange) {
                return dataservice.user.saveUser(ko.toJS(userData), showAccountChange)
                        .then(function (data) {
                            presenter.toast(ownSettings === true ?
                                    "app:services.save_settings_success"
                                    : "app:services.saveuser_success",
                                    presenter.LEVEL.SUCCESS);
                            return data;
                        }).fail(function (msg) {
                    presenter.toast(ownSettings === true ?
                            "app:services.save_settings_failed"
                            : "app:services.saveuser_failed",
                            presenter.LEVEL.DANGER, msg);
                    return msg;
                });

            };

            module.activate = function (userId) {
                return dataservice.user.activate(userId)
                        .then(function (data) {
                            presenter.toast("app:services.saveuser_success", presenter.LEVEL.SUCCESS);
                            return data;
                        }).fail(function (msg) {
                    presenter.toast("app:services.saveuser_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });

            };

            module.accountMigration = function(username) {
                return dataservice.user.accountMigration(username);
            };

            module.removeUser = function (username, adminUser, adminPassword, callback) {
                return dataservice.user.removeUser(username, adminUser, adminPassword)
                        .fail(function (msg) {
                            if(msg === "[OK:Workaround]") {
                                presenter.toast("app:services.remove_user_success", presenter.LEVEL.SUCCESS);
                                if( callback ) {
                                    callback();
                                }
                            } else {                            
                                presenter.toast("app:services.remove_user_failed", presenter.LEVEL.DANGER, msg);
                            }
                            return msg;
                        });
            };

            return module;
        });

