define(
    'viewmodels/modals/login',[
        "plugins/dialog",
        "plugins/router",
        "knockout",
        "underscore",
        "base/forms",
        "dataservices/dataservice",
        "i18next",
        "base/presenter"
    ],
    function (dialog, router, ko, _, forms, dataservice, i18n, presenter) {
        "use strict";

        var LoginModal = function (params) {
            
            var self = this,
                lasterror = ko.observable(""),
                opts = _.extend({
                    username: ""
                }, params || {}),
                listenToRouter = router.on('router:route:activating');

            listenToRouter.then(function() {
                listenToRouter.off();
                dialog.close(self, {});
            });

            self.isLoginShown = ko.observable(true);
            self.loginForm = {
                username: ko.observable(opts.username).extend({
                    validate: {
                        name: "username", type: "text", checks: ["required"]
                    }
                }),
                password: ko.observable("").extend({
                    validate: {name: "password", type: "password", checks: ["required"], message: lasterror }
                })
            };
            self.pwresetForm = {
                username: ko.observable(opts.username).extend({
                    validate: {
                        name: "username_reset", type: "text", checks: ["required"]
                    }
                })
            };

            function login() {
                lasterror(i18n.t("app:validation.password_required"));
                return dataservice.user.login(self.loginForm.username(), self.loginForm.password())
                    .then(function(data) {                                               
                        return data.responseMessage;
                    })
                    .fail(function (data) {
                        lasterror(data.responseMessage || "ERROR");
                        self.loginForm.password("");     
                        forms.checkForm(self.loginForm, false);
                        return "";
                    });
            }

            function resetPassword() {
                dataservice.user.resetPassword(self.pwresetForm.username())
                    .then(function (data) {
                        self.loginForm.username(self.pwresetForm.username());
                        self.isLoginShown(true);
                        presenter.toast("app:messages.password_reset_success", presenter.LEVEL.SUCCESS);
                        return data;
                    })
                    .fail(function (err) {
                        presenter.toast("app:messages.password_reset_failed", presenter.LEVEL.DANGER);
                        return err;
                    });
            }

            self.register = function () {
                router.navigate("register");
                dialog.close(self, {});
            };

            self.showLogin = function () {
                self.isLoginShown(true);
            };

            self.showReset = function () {
                self.isLoginShown(false);
            };

            self.cancel = function () {
                dialog.close(self, {});
            };

            self.ok = function () {
                if (self.isLoginShown() && forms.checkForm(self.loginForm, true)) {
                    login().then(function (token) {
                        self.loginForm.password("");
                        dialog.close(self, {token: token, username: self.loginForm.username()});
                    });
                } else if (forms.checkForm(self.pwresetForm, true)) {
                    resetPassword();
                }
            };

        };

        LoginModal.show = function (params) {
            return dialog.show(new LoginModal(params));
        };                

        return LoginModal;
    });
