define(
    'viewmodels/register',[
        'plugins/router',
        'jquery',
        'knockout',
        'i18next',
        'base/forms',
        'contexts/context',
        'base/config'
    ],
    function (router, $, ko, i18n, forms, context, config) {
        "use strict";

        var module = {
            registerForm: {
                username: ko.observable("").extend({
                    validate: {name: "username", type: "text", label: "username", checks: ["pattern"], 
                                    "pattern" : "^[a-zA-Z0-9_@\\.-]{3,}$" }
                }),
                firstname: ko.observable("").extend({
                    validate: {name: "firstname", type: "text", label: "firstname", checks: ["required"]}
                }),
                lastname: ko.observable("").extend({
                    validate: {name: "lastname", type: "text", label: "lastname", checks: ["required"]}
                }),
                email: ko.observable("").extend({
                    validate: {name: "email_address", type: "text", label: "email_address", checks: ["required","pattern"],
                                    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$" }
                }),
                phone: ko.observable("").extend({
                    validate: {name: "phone", type: "tel", label: "phone", checks: ["required","pattern"],
                                    pattern: "^\\+?[0-9-\\(\\) ]{4,20}$" }
                }),
                department: ko.observable("").extend({
                    validate: {name: "department", type: "text", label: "department", checks: ["required"]}
                }),
                password: ko.observable("").extend({
                    validate: {name: "password", type: "password", label: "password", checks: ["required","pattern"],
                    "pattern" : "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&#:;{}<>,?~_+-=|])(?=.{12,})"}
                }),                
                emailLanguage: ko.observable("").extend({
                    selectOptions: {options: config.languages, optionsText: "name", optionsValue: "key"},
                    validate: {name: "emailLanguage", type: "select", label: 'emailLanguage', checks: ["required"]}
                })
            }
        };        
        
        module.registerForm.confirmpassword = ko.observable("").extend({
            validate: {
                name: "confirmpassword",
                type: "password",
                checks: ["required", "match"],
                label: "confirmpassword",     
                matchField : module.registerForm.password
            }
        });              
        
        module.clearFields = function() {
            forms.checkForm(module.registerForm, false);
            module.registerForm.firstname("")
                    .username("")
                    .lastname("")
                    .email("")
                    .phone("")
                    .department("")
                    .password("")
                    .emailLanguage("")
                    .confirmpassword("");            
        };
        
        module.register = function () {              
            if (forms.checkForm(module.registerForm, true)) {
                context.user.register(forms.serialize(module.registerForm))
                    .then(function (data) {
                        router.navigate("#");
                        module.clearFields();
                    });
            }
        };

        module.canActivate = function () {
            return true;
        };

        module.activate = function () {
            if (arguments.length > 0 && $.isPlainObject(arguments[arguments.length - 1])) {
                var query = arguments[arguments.length - 1];
                module.registerForm.firstname(query.firstname || "")
                    .username(query.username || "")
                    .lastname(query.lastname || "")
                    .email(query.email || "")
                    .phone(query.phone || "");
            }
            module.registerForm.password("");
            return true;
        };

        return module;
    }
);
