define('dataservices/attachment',
    [
        'amplify'
    ],
    function (amplify) {
        "use strict";

        var module = {};

        module.registerServices = function() {

            amplify.request.define('get_attachments', 'ajax', {
                url: 'issue/{issueId}/attachment',
                type: 'GET'
            });

            amplify.request.define('get_attachment', 'ajax', {
                url: 'issue/{issueId}/attachment/{id}',
                type: 'GET'
            });

            amplify.request.define('get_attachment_content', 'ajax', {
                url: 'issue/{issueId}/attachment/{id}/content',
                type: 'GET'
            });

            amplify.request.define('delete_attachment', 'ajax', {
                url: 'issue/{issueId}/attachment/{id}',
                type: 'DELETE'
            });
        };

        module.getAttachmentsByIssueId = function(issueId) {
            return amplify.request('get_attachments', {
                issueId: issueId
            });
        };

        module.getAttachmentById = function (issueId, id) {
            return amplify.request('get_attachment', {
                issueId: issueId,
                id: id
            });
        };

        module.getAttachmentContentById = function (issueId, id) {
            return amplify.request('get_attachment_content', {
                issueId: issueId,
                id: id
            });
        };

        module.deleteAttachment = function (issueId, id) {
            return amplify.request('delete_attachment', {
                issueId: issueId,
                id: id
            });
        };

        return module;
    });
