define('dataservices/businessenum',
    [
        'amplify'
    ],
    function (amplify) {
        "use strict";

        var model = {};

        model.registerServices = function () {

            amplify.request.define('get_all_classifications', 'ajax', {
                url: 'classification',
                type: 'GET'
            });

            amplify.request.define('get_classification_by_id', 'ajax', {
                url: 'classification/{id}',
                type: 'GET'
            });

            amplify.request.define('get_all_issueobjects', 'ajax', {
                url: 'issueobject',
                type: 'GET'
            });

            amplify.request.define('get_issueobject_by_id', 'ajax', {
                url: 'issueobject/{id}',
                type: 'GET'
            });

            amplify.request.define('get_all_issuetypes', 'ajax', {
                url: 'issuetype',
                type: 'GET'
            });

            amplify.request.define('get_issuetype_by_id', 'ajax', {
                url: 'issuetype/{id}',
                type: 'GET'
            });

            amplify.request.define('get_all_sources', 'ajax', {
                url: 'issuesource',
                type: 'GET'
            });

            amplify.request.define('get_source_by_id', 'ajax', {
                url: 'issuesource/{id}',
                type: 'GET'
            });
        }

        model.getClassifications = function () {
            return amplify.request('get_all_classifications', {});
        };

        model.getClassificationById = function (id) {
            return amplify.request('get_classification_by_id', { id: id });
        };

        model.getIssueObjects = function () {
            return amplify.request('get_all_issueobjects', {});
        };

        model.getIssueObjectById = function (id) {
            return amplify.request('get_issueobject_by_id', { id: id });
        };

        model.getIssueTypes = function () {
            return amplify.request('get_all_issuetypes', {});
        };

        model.getIssueTypeById = function (id) {
            return amplify.request('get_issuetype_by_id', { id: id });
        };

        model.getSources = function () {
            return amplify.request('get_all_sources', {});
        };

        model.getSourceById = function (id) {
            return amplify.request('get_source_by_id', { id: id });
        };

        return model;
    });
