define('datamocks/user',
    [
        'amplify'
    ],
    function (amplify) {
        "use strict";

        var module = {};

        module.registerServices = function () {

            amplify.request.define('get_all_users', function (settings) {
                settings.success({}); // TODO: provide mock implementation
            });

            amplify.request.define('get_user_by_name', function (settings) {
                settings.success({
                    "firstName": "Genna",
                    "lastName": "mustermann",
                    "email": "email@domain",
                    "phone": "0123 456 789",
                    "department": "department",
                    "userName": "max",
                    "userRole": "admin",
                    "supervisor": null,
                    "substitute": null,
                    "supplier": null,
                    "language": null,
                    "active": false
                });
            });

            amplify.request.define('get_user_current', function (settings) {
                settings.success({
                    "firstName": "Genna",
                    "lastName": "Mustermann",
                    "email": "email@domain",
                    "phone": "0123 456 789",
                    "department": "department",
                    "userName": "max",
                    "userRole": "admin",
                    "supervisor": null,
                    "substitute": null,
                    "supplier": null,
                    "language": null,
                    "active": false
                });
            });

            amplify.request.define('user_register', function (settings) {
                settings.success({}); // TODO: provide mock implementation
            });

            amplify.request.define('create_user', function (settings) {
                settings.success({}); // TODO: provide mock implementation
            });

            amplify.request.define('update_user', function (settings) {
                settings.success({}); // TODO: provide mock implementation
            });

            amplify.request.define('user_login', function (settings) {
                settings.success('1234567890ABCDEFG');
            });

            amplify.request.define('user_reset_password', function (settings) {
                settings.success({}); // TODO: provide mock implementation
            });

            amplify.request.define('user_new_password', function (settings) {
                settings.success({}); // TODO: provide mock implementation
            });

        };

        return module;
    });
