define('contexts/admin',
    [
        'jquery',
        'knockout',
        'base/store',
        'dataservices/dataservice',
        'base/presenter'
    ],
    function ($, ko, store, dataservice, presenter) {
        "use strict";

        var module = {};

        module.getUsers = function (offset, limit, searchterm, order, asc, filter) {
            if (dataservice.token()) {
                return dataservice.user.getUsers(offset, limit, searchterm, order, asc, filter)
                    .then(function (data) {
                        data.resultsPage = $.map(data.resultsPage, ko.mapper.fromJS);
                        return data;
                    });
            }
            return $.when({resultsPage: [], totalCount: 0});
        };

        module.getUserByName = function (username) {
            return dataservice.user.getUserByName(username)
                .then(function (user) {
                    return ko.mapper.fromJS(user);
                });
        };

        module.getIssues = function (offset, limit, searchterm, filter, order, asc) {
            if (dataservice.token()) {
                return dataservice.issue.getIssues(offset, limit, searchterm, filter, order, asc)
                    .then(function (data) {
                        data.resultsPage = $.map(data.resultsPage, ko.mapper.fromJS);
                        return data;
                    });
            }
            return $.when({resultsPage: [], totalCount: 0});
        };

        module.getIssue = function (id, hashCode) {
            return dataservice.issue.getIssueById(id, hashCode)
                .then(function (data) {
                    return data;
                });
        };

        module.newIssue = function (issue) {
            return dataservice.issue.newIssue(issue)
                .then(function (data) {
                    presenter.toast("app:services.new_issue_success", presenter.LEVEL.SUCCESS);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.new_issue_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.saveIssue = function (issueData) {
            return dataservice.issue.saveIssue(ko.toJS(issueData))
                .then(function (data) {
                    presenter.toast("app:services.save_issue_success", presenter.LEVEL.SUCCESS);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.save_issue_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.assignIssue = function (issueData) {
            return dataservice.issue.assignIssue(ko.toJS(issueData))
                .done(function (data) {
                    //presenter.toast("app:services.assign_issue_success", presenter.LEVEL.SUCCESS);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.assign_issue_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.closeIssue = function (issueData) {
            return dataservice.issue.closeIssue(ko.toJS(issueData))
                .done(function (data) {
                    //presenter.toast("app:services.close_issue_success", presenter.LEVEL.SUCCESS);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.close_issue_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.rejectIssue = function (issueId) {
            return dataservice.issue.rejectIssue(issueId)
                .done(function (data) {
                    //presenter.toast("app:services.reject_issue_success", presenter.LEVEL.SUCCESS);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.reject_issue_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.acceptIssue = function (issueId) {
            return dataservice.issue.acceptIssue(issueId)
                .done(function (data) {
                    //presenter.toast("app:services.accept_issue_success", presenter.LEVEL.SUCCESS);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.accept_issue_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.reactivateIssue = function (issueId) {
            return dataservice.issue.reactivateIssue(issueId)
                .done(function (data) {
                    //presenter.toast("app:services.reject_issue_success", presenter.LEVEL.SUCCESS);
                    return data;
                }).fail(function (msg) {
                    presenter.toast("app:services.reactivate_issue_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };
        
        module.deeplTranslation = function (message, language) {
        	return dataservice.issue.deeplTranslation(message, language)
        		.then(function (data) {
        			return data;
        		}).fail(function (msg) {
        			presenter.toast("app:services.deepl_translation_failed", presenter.LEVEL.DANGER, msg);
        			return msg;
        		});
        }

        return module;
    });

