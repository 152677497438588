define('models/model',
    [
        'models/issue',
        'models/user',
        'models/pagination',
        'models/suppliers'
    ],
    function (Issue, User, pagination, Suppliers) {
        "use strict";

        var module = {
            Issue: Issue,
            User: User,
            Pagination: pagination.Model,
            Suppliers: Suppliers
        };                
        
        return module;
    });
