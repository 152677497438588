define('dataservices/issue',
    [
        'amplify',
        'underscore'
    ],
    function (amplify, _) {
        "use strict";

        var module = {};

        module.registerServices = function () {

            amplify.request.define('get_all_issues', 'ajax', {
                url: 'issue',
                type: 'GET'
            });

            amplify.request.define('get_issue_by_id', 'ajax', {
                url: 'issue/{id}',
                type: 'GET'
            });

            amplify.request.define('get_by_hashcode', 'ajax', {
                url: 'issue/{id}/hash/{hashCode}/',
                type: 'GET'
            });

            amplify.request.define('get_issue_parameter', 'ajax', {
                url: 'issue/{id}/parameter',
                type: 'GET'
            });

            amplify.request.define('new_issue', 'ajax', {
                url: 'issue',
                type: 'POST'
            });

            amplify.request.define('update_issue', 'ajax', {
                url: 'issue/{id}',
                type: 'PUT'
            });

            amplify.request.define('assign_issue', 'ajax', {
                url: 'issue/{id}/assign',
                type: 'PUT'
            });

            amplify.request.define('close_issue', 'ajax', {
                url: 'issue/{id}/close',
                type: 'PUT'
            });

            amplify.request.define('reject_issue', 'ajax', {
                url: 'issue/{id}/reject',
                type: 'PUT'
            });

            amplify.request.define('accept_issue', 'ajax', {
                url: 'issue/{id}/accept',
                type: 'PUT'
            });

            amplify.request.define('reactivate_issue', 'ajax', {
                url: 'issue/{id}/reactivate',
                type: 'PUT'
            });
            
            amplify.request.define('deepl_translation', 'ajax', {
            	url: 'translation/message',
            	type: 'POST'
            });
        };

        module.getIssues = function (offset, limit, searchterm, order, asc, filter) {
            return amplify.request('get_all_issues', _.extend({
                offset: offset,
                limit: limit,
                searchterm: searchterm,
                order: order,
                asc: asc
            }, filter));
        };

        module.getIssueById = function (issueId, hashCode) {
            if( hashCode ) {
                return amplify.request('get_by_hashcode', {
                    id : issueId,
                    hashCode: hashCode
                });
            } else {
                return amplify.request('get_issue_by_id', {
                    id: issueId
                });
            }
        };

        module.getIssueParameter = function (issueId) {
            return amplify.request('get_issue_parameter', {
                id: issueId
            });
        };

        module.newIssue = function (issueData) {
            return amplify.request('new_issue', issueData);
        };

        module.saveIssue = function (issueData) {
            return amplify.request('update_issue', issueData);
        };

        module.assignIssue = function (issueData) {
            return amplify.request('assign_issue', issueData);
        };

        module.closeIssue = function (issueData) {
            return amplify.request('close_issue',issueData) ;
        };

        module.rejectIssue = function (issueId) {
            return amplify.request('reject_issue', {
                id: issueId
            });
        };

        module.acceptIssue = function (issueId) {
            return amplify.request('accept_issue', {
                id: issueId
            });
        };

        module.reactivateIssue = function (issueId) {
            return amplify.request('reactivate_issue', {
                id: issueId
            });
        };
        
        module.deeplTranslation = function (message) {
        	return amplify.request('deepl_translation', message);
        }

        return module;
    });
