define(
    'viewmodels/passwordreset',[
        'durandal/app',
        "plugins/router",
        'jquery',
        'knockout',
        'contexts/context',
        'base/forms',
        'i18next',
        'base/presenter'
    ],
    function (app, router, $, ko, context, forms, i18n, presenter) {
        "use strict";        
        var self = this,
                lasterror = ko.observable("");
        
        var module = {
            newPasswordForm: {
                token: ko.observable("").extend({
                    validate: {name: "token", type: "text", checks: ["required"], label: "token"}
                }),
                username: ko.observable("").extend({
                    validate: {name: "username", type: "text", checks: ["required"], label: "username"}
                }),
                password: ko.observable("").extend({
                    validate: {name: "password", type: "password", label: "password", checks: ["required","pattern"],
                    "pattern" : "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&#:;{}<>,?~_+-=|])(?=.{12,})"}
                })                
            }
        };      
        
        module.newPasswordForm.confirmpassword = ko.observable("").extend({
            validate: {
                name: "confirmpassword",
                type: "password",
                checks: ["required", "match"],
                label: "confirmpassword",     
                matchField : module.newPasswordForm.password
            }
        });
        
        module.newPassword = function () {                    
            if (forms.checkForm(module.newPasswordForm, true)) {                       
                context.user.newPassword(module.newPasswordForm.token(), module.newPasswordForm.username(), module.newPasswordForm.password())
                    .then(function (token) {
                        router.navigate("#");
                    })
                    .fail(function () {
                        module.newPasswordForm.password("");
                        module.newPasswordForm.confirmpassword("");
                    });
            }
        };

        module.canActivate = function () {
            return true;
        };

        module.activate = function () {
            if (arguments.length > 0 && $.isPlainObject(arguments[arguments.length - 1])) {
                var query = arguments[arguments.length - 1];
                module.newPasswordForm.token(query.token || "");
            }
            module.newPasswordForm.password("");
            module.newPasswordForm.confirmpassword("");
            return true;
        };

        return module;
    }
);
