define(
    'viewmodels/modals/user_edit',[
        "jquery",
        "plugins/dialog",
        "knockout",
        "underscore",
        "base/forms",
        "contexts/context",
        "i18next",
        "base/config",
        "viewmodels/modals/confirm_dialog",
        "viewmodels/modals/login",
        "chosen",        
        "models/model"
    ],
    function ($, dialog, ko, _, forms, context, i18n, config, ConfirmModal, LoginModal, chosen, model) {
        "use strict";

        var parentUser;                                                       
        
        function findUserNiceNameByUserName( users, userName ) {
            if( userName ) {
                for( var i = 0; i < users.length; i++ ) {
                    if( users[i].userName() === userName ) {
                        return users[i].niceNameWithDepartment();
                    }
                }
            }
            return "";
        }               
        
        var UserModal = function (user, suppliers, ownSettings, closeCallback, showAccountChange) {
            parentUser = user;
            var self = this;           
                                                
            self.ownSettings = ownSettings;
            self.showAccountChange = showAccountChange === true;
            self.isLoadingSupplier = $.isArray( suppliers ) && suppliers.length > 0;
            
            self.language = user.emailLanguage();
            self.closeCallback = closeCallback;
            self.hasAdminAccess = context.session.hasAdminAccess();
                        
            self.selectedSupplierIds = [];
            model.Suppliers.setSuppliers([]);
            if( self.isLoadingSupplier ) {
                model.Suppliers.setSuppliers(suppliers);                
                self.selectedSupplierIds = model.Suppliers.extractSupplierIds(user.suppliers());                                
            }
                        
            self.userRolle = user.userRole() || "issuer";
            self.niceName = user.niceName();            
            self.userName = user.userName();
            self.hasUserRole = $.type( user.userRole() ) === "string" 
                                    && $.trim( user.userRole() ).length > 0;
                                                   
            self.editUserForm = {
                userName: ko.observable(user.userName()),
                oldUserNames: ko.observable( user.oldUserNames() ),    
                ownSettings: ownSettings,
                userStatus : ko.observable(user.active() === true ? "active" : "inactive"),
                isContactPerson: ko.observable(user.isContactPerson ? user.isContactPerson() : false),
                firstName: ko.observable(user.firstName() || "").extend({
                    validate: {name: "firstname", type: "text", checks: ["required"]}
                }),
                lastName: ko.observable(user.lastName() || "").extend({
                    validate: {name: "lastname", type: "text", checks: ["required"]}
                }),
                email: ko.observable(user.email() || "").extend({
                    validate: {name: "email_address", type: "email", checks: ["required","pattern"],
                                    pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$" }
                }),
                source: ko.observable(user.source() || "").extend({
                	validate: {name: "source", type: "text", checks: [""]}
                }),
                issueReport: ko.observable(user.issueReport() || "").extend({
                	validate: {name: "issueReport", type: "boolean", checks: [""] }
                }),
                phone: ko.observable(user.phone() || "").extend({
                    validate: {name: "phone", type: "tel", checks: ["required","pattern"],
                                    pattern: "^\\+?[0-9-\\(\\) ]{4,20}$" }
                }),
                department: ko.observable(user.department() || "").extend({
                    validate: {name: "department", type: "text", checks: ["required"]}
                }),
                supplier: ko.observableArray( self.selectedSupplierIds ).extend({
                    selectOptions: {options: suppliers, optionsText: "idWithName", optionsValue: "id"},
                    validate: {name: "supplier", type: "chosen", checks: [""]}
                }),
                supplierObjects : ko.observableArray( self.hasAdminAccess ? [] : user.suppliers() ),
                supervisor: ko.observable("").extend({
                    selectOptions: {options: [], optionsText: "displayName", optionsValue: "userName"},
                    validate: {name: "supervisor", type: "select", checks: [""]}
                }),
                supervisorName : ko.observable(""),
                substitute: ko.observable("").extend({
                    selectOptions: {options: [], optionsText: "displayName", optionsValue: "userName"},
                    validate: {name: "substitute ", type: "select", checks: [""]}
                }),
                substituteName : ko.observable(""),
                userRole: ko.observable(self.userRolle),
                hasUserRole : self.hasUserRole,
                emailLanguage: ko.observable( config.getAvailableLanguage( user.emailLanguage() ) ) .extend({
                    selectOptions: {options: config.languages, optionsText: "name", optionsValue: "key"},
                    validate: {name: "emailLanguage ", type: "select", label: 'emailLanguage', checks: [""]}
                }),
                showRoleSelect : ko.observable( self.hasAdminAccess && !self.ownSettings ),
                showNameInput : ko.observable( self.hasAdminAccess || self.showAccountChange ),             
                showPassword : ko.observable( ownSettings ),
                showSupplierSelect : ko.observable( self.hasAdminAccess ),
                showSuperVisorSelect : ko.observable( self.hasAdminAccess ),
                showSubstituteSelect : ko.observable( self.hasAdminAccess ),
                showUserActivationSwitch : ko.observable( self.hasAdminAccess && !self.ownSettings ),                
                showAccountChange : ko.observable( self.showAccountChange ),
                showAccountChangeSimple: ko.observable( !(self.selectedSupplierIds.length > 1) ),                
                showSupervisor: ko.observable( !self.showAccountChange ),
                showSubstitute: ko.observable( !self.showAccountChange )
            };
            
            self.editUserForm.showIsContactSwitch = ko.computed( function() {
                return !self.ownSettings && self.editUserForm.userRole() === 'editor';
            }, self);
               
            self.editUserForm.showSupervisorSubstitute = ko.computed( function() {
                return !self.showAccountChange && self.editUserForm.userRole() === 'editor';
            }, self);

            self.editUserForm.password_old = ko.observable("").extend({
                    validate: {name: "password_old", 
                               type: "password", 
                               checks: self.ownSettings ? ["required"] : [""] }
            });
            self.editUserForm.password = ko.observable("").extend({
                    validate: { name: "password_new", 
                    			type: "password", 
                                checks: ["pattern"],
                    			pattern : "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&#:;{}<>,?~_+-=|])(?=.{12,})|^$" }
            });                        
            
            self.editUserForm.confirmpassword = ko.observable("").extend({
                    validate: { 
                        name: "confirmpassword", 
                        type: "password", 
                        checks: ["match"],
                        matchField : self.editUserForm.password}
            });
            
            self.editUserForm.active = ko.computed(function() {
                var status = self.editUserForm.userStatus();
                return status === 'active';
            }, self.editUserForm );                     
            
            self.cancel = function () {
                dialog.close(self, self.editUserForm);
                
                if( self.closeCallback ) {
                    self.closeCallback();
                }
            };
            
            self.loadSuppliers = ko.computed(function() {
                var role = self.editUserForm.userRole();
                if( (!self.isLoadingSupplier)
                      && self.hasAdminAccess                      
                        && role === 'editor' 
                            && !model.Suppliers.hasSuppliers() ) {    
                    self.isLoadingSupplier = true;
                    context.supplier.getSuppliers()
                        .then(function (data) {                        
                            model.Suppliers.setSuppliers( data.resultsPage );
                            self.editUserForm.supplier.options( data.resultsPage );                            
                            self.selectedSupplierIds = model.Suppliers.extractSupplierIds(parentUser.suppliers());                                                        
                            self.editUserForm.supplier(self.selectedSupplierIds);                            
                            $('.chosen-select').chosen().trigger('chosen:updated');
                        });
                }
            }, self).extend({rateLimit: 500});
            
            self.loadUsers = ko.computed(function () {
                var supplierIds = self.editUserForm.supplier();                             
                if($.isArray(supplierIds) && supplierIds.length > 0
                        && ( self.hasAdminAccess 
                                || user.supervisor() 
                                || user.substitute() ) ) {                                                            
                    context.supplier.getSupplierUsers("" + supplierIds)
                        .then(function (data) {
                            var users = [];          
                            
                            for( var i = 0; i < data.resultsPage.length; i++ ) {
                                var u = data.resultsPage[i];
                                if(ko.unwrap( u.userName ) 
                                        !== ko.unwrap( parentUser.userName ) ) {
                                    users.push(u);
                                }
                            }                            
                            self.editUserForm.supervisor.options(users);
                            self.editUserForm.supervisor(user.supervisor() || self.editUserForm.supervisor() || "");
                            self.editUserForm.substitute.options(users);
                            self.editUserForm.substitute(user.substitute() || self.editUserForm.substitute() || "");

                            self.editUserForm.supervisorName(findUserNiceNameByUserName(users, user.supervisor()));
                            self.editUserForm.substituteName(findUserNiceNameByUserName(users, user.substitute()));
                        });
                } else {
                    self.editUserForm.supervisor.options([]);                    
                    self.editUserForm.substitute.options([]);
                }              
            }, self).extend({rateLimit: 100});

            self.deactivate = function () {
                self.loadUsers.dispose();
            };                        
        };

        UserModal.prototype.ok = function () {
            var removeUser = this.editUserForm.userStatus() === 'delete',
                self = this;

            if( removeUser ) {                           
                
                ConfirmModal.show({
                            title : i18n.t("app:titles.delete_user"),
                            message : i18n.t("app:messages.delete_user_confirm")
                        })
                        .then(function(result) {
                            if( result && result.password() ) {
                                context.user.removeUser(
                                        self.userName,
                                        context.session.info().userName,
                                        result.password(), function() {
                                            dialog.close(self, self.editUserForm);
                                            
                                            if( self.closeCallback ) {
                                                self.closeCallback({userDeleted:self.userName});
                                            }
                                        }); 
                            }                            
                        });                
                    return;
            } 
            
            var commit = forms.checkForm(this.editUserForm, true);

            if (commit && (this.editUserForm.userRole() !== "editor" || 
                    ( $.isArray(this.editUserForm.supplier()) && this.editUserForm.supplier().length > 0 ) ) ) {
            	
                var data = forms.serialize(this.editUserForm, parentUser);
                
                delete data.supplierIds;
                delete data.supplierObjects;                                
                delete data.suppliers;
                delete data.hasUserRole;
                delete data.oldUserNames;                
                
                for( var key in data ) {
                    if( key.indexOf('show') === 0 ) {
                       delete data[key];
                    }
                }
                
                if( data.supplier && data.supplier.length > 0 ) {
                    data.suppliers = model.Suppliers.getSuppliersByIds( data.supplier );
                }
                
                if( self.ownSettings ) {
                    var sessionInfo = context.session.info();
                    sessionInfo.firstName = data.firstName;
                    sessionInfo.lastName = data.lastName;
                    context.session.info( sessionInfo );
                }
                
                context.user.save(data, self.ownSettings, self.showAccountChange )
                    .then(function (updatedUserDto) {
                        forms.updateFromDto(parentUser, updatedUserDto);
                        dialog.close(self, self.editUserForm);
                        
                        if( self.ownSettings ) {   
                            var lang = updatedUserDto.emailLanguage;
                                                                                    
                            if( lang && lang !== self.language ) {
                                context.session.reloadPage(lang, "app:services.save_settings_success");
                            }                            
                        }
                    });
            } else if (this.editUserForm.userRole() === "editor" 
                    && (!$.isArray( this.editUserForm.supplier() ) || this.editUserForm.supplier().length === 0 ) ) {
                this.editUserForm.supplier.hasError(true).validationMessage(i18n.t("app:validation.value_required"));
                commit = false;
            }
        };

        UserModal.show = function (user, ownSettings, closeCallback, showAccountChange) {
            if(user.userRole() === "editor") {        
                return context.supplier.getSuppliers()
                    .then(function (data) {
                        return dialog.show(new UserModal(user, data.resultsPage, ownSettings, closeCallback, showAccountChange));
                    });
            }
            return dialog.show(new UserModal(user, null, ownSettings, closeCallback, showAccountChange));
        };               

        return UserModal;
    });
