define(
        'viewmodels/modals/translate_info',[
            "plugins/dialog",
            "knockout",
            "base/forms",
            "contexts/context",
            "i18next"
        ],
        function (dialog, ko, forms, context, i18n) {
            "use strict";

            var TranslateInfoModal = function (params) {
                var self = this;

                self.title = params.title || "";
                self.message = params.message || "";
            };

            TranslateInfoModal.prototype.ok = function () {
                var self = this;
                dialog.close(self);
            };

            TranslateInfoModal.show = function (params) {
                return dialog.show(new TranslateInfoModal(params));
            };

            return TranslateInfoModal;
        });
