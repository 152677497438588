define('contexts/attachment',
    [
        'knockout',
        'base/store',
        'dataservices/dataservice',
        'base/presenter',
        'i18next'
    ],
    function (ko, store, dataservice, presenter, i18n) {
        "use strict";

        var model = {};

        model.getAttachmentsByIssueId = function (issueId) {
            return dataservice.attachment.getAttachmentsByIssueId(issueId).then(function(data) {
                return data;
            });
        };

        model.getAttachmentById = function (issueId, id) {
            return dataservice.attachment.getAttachmentById(issueId, id).then(function (data) {
                return data;
            });
        };

        model.getAttachmentContentById = function (issueId, id) {
            return dataservice.attachment.getAttachmentContentById(issueId, id).then(function (data) {
                return data;
            });
        };

        model.deleteAttachment = function(issueId, id, userId, currentUser) {
			if(userId != currentUser){
				//presenter.toast("your not the owner of the attachment and there not allowed to delete it" , presenter.LEVEL.DANGER);
				presenter.toast(i18n.t("app:services.not_owner_of_attachment"), presenter.LEVEL.DANGER);
				return null;
			}
            return dataservice.attachment.deleteAttachment(issueId, id).then(function (data) {
                return data;
            });
        };

        return model;
    });
