define('dataservices/config',
    [
        'amplify'
    ],
    function (amplify) {
        "use strict";

        var module = {};

        module.registerServices = function () {

            amplify.request.define('get_config', 'ajax', {
                url: 'config/attachments',
                type: 'GET'
            });

        };

        module.getConfig = function () {
            return amplify.request('get_config', {});
        };

        return module;
    });
