define('base/koExtenders',
    [
        'jquery',
        'knockout',
        'underscore',
        'base/presenter'
    ],
    function ($, ko, _, presenter) {
        "use strict";

        ko.extenders.pagination = function (target, options) {
            var loader = null;
            target.page = {
                searchterm: ko.observable(options.searchterm || ""),
                limit: ko.observable(options.limit || 10),
                offset: ko.observable(options.offset || 0),
                total: ko.observable(0)
            };

            if (_.isFunction(options.loader)) {
                loader = ko.computed(function () {
                    options.loader(target.page.offset(), target.page.limit(), target.page.searchterm())
                        .then(function (data) {
                            target.page.total(data.totalCount || 0);
                            target(data.resultsPage || []);
                            presenter.toast("app:services.users_success", presenter.LEVEL.SUCCESS);
                        })
                        .fail(function (msg) {
                            target.page.total(0);
                            target([]);
                            presenter.toast("app:services.users_failed", presenter.LEVEL.DANGER);
                        });
                }, target).extend({ notify: "always" });
            }

            return target;
        };
    });
