define(
    'viewmodels/import',[
        'knockout',
        'underscore',
        'contexts/context',
        'dataservices/dataservice',
        'base/presenter',
        'i18next'
    ],
    function (ko, _, context, dataservice, presenter, i18n) {
        "use strict";

        var module = {
            session: context.session
        };

        module.supplierImportFiles = ko.observableArray().extend({rateLimit: 100});
        module.issueImportFiles = ko.observableArray().extend({rateLimit: 100});

        module.issueListResult = ko.observableArray([]);
        module.supplierListResult = ko.observableArray([]);


        module.autoUploadIssueList = ko.computed(function () {
            if (module.issueImportFiles().length) {
                dataservice.uploadIssueList($("form[name='upload_issues']"))
                    .then(function (data) {
                        var result = data.responseText || [];
                        var validated = _.extend(module.issueImportFiles()[0], {
                            valid: _.every(result, function (item) {
                                return item === null || item.level !== 'ERROR';
                            }),
                            details: result
                        });
                        module.issueListResult([validated]);
                        presenter.toast(i18n.t("app:services.upload_success"), presenter.LEVEL.SUCCESS);
                    })
                    .fail(function (msg) {
                        module.issueListResult([]);
                        presenter.toast(i18n.t("app:services.upload_failed"), presenter.LEVEL.DANGER, msg);
                    });
            }
        });

        module.startIssueImport = function () {
            if (module.issueImportFiles().length) {
                dataservice.importIssueList($("form[name='upload_issues']"))
                    .then(function (result) {
                        presenter.toast(i18n.t("app:services.import_success"), presenter.LEVEL.SUCCESS);
                    })
                    .fail(function (msg) {
                        presenter.toast(i18n.t("app:services.import_failed"), presenter.LEVEL.DANGER, msg);
                    })
                    .always(function () {
                        module.issueImportFiles([]);
                        module.issueListResult([]);
                    });
            }
        };


        module.autoUploadSupplierList = ko.computed(function () {
            if (module.supplierImportFiles().length) {
                dataservice.uploadSupplierList($("form[name='upload_suppliers']"))
                    .then(function (data) {
                        var result = data.responseText || [],
                            validated = _.extend(module.supplierImportFiles()[0], {
                                valid: _.every(result, function (item) {
                                    return item === null || item.level !== 'ERROR';
                                }),
                                details: result
                            });
                        module.supplierListResult([validated]);
                        presenter.toast(i18n.t("app:services.upload_success"), presenter.LEVEL.SUCCESS);
                    })
                    .fail(function (msg) {
                        module.supplierListResult([]);
                        presenter.toast(i18n.t("app:services.upload_failed"), presenter.LEVEL.DANGER, msg);
                    });
            }
        });

        module.startSupplierImport = function () {
            if (module.supplierImportFiles().length) {
                dataservice.importSupplierList($("form[name='upload_suppliers']"))
                    .then(function (result) {
                        presenter.toast(i18n.t("app:services.import_success"), presenter.LEVEL.SUCCESS);
                    })
                    .fail(function (msg) {
                        presenter.toast(i18n.t("app:services.import_failed"), presenter.LEVEL.DANGER, msg);
                    })
                    .always(function () {
                        module.supplierImportFiles([]);
                        module.supplierListResult([]);
                    });
            }
        };

        module.deactivate = function () {
            if ($("form[name='upload_suppliers']").length) {
                if ($.isFunction($("form[name='upload_suppliers']").resetForm)) {
                    $("form[name='upload_suppliers']").resetForm();
                }
                $("form[name='upload_suppliers']")[0].reset();
            }

            module.supplierImportFiles([])
                .issueImportFiles([])
                .issueListResult([])
                .supplierListResult([]);
        };

        module.canActivate = function () {
            return context.session.requireAccess();
        };

        module.activate = function () {
            return true;
        };

        return module;
    });
