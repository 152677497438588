define('base/callbacks',
    [ 'jquery' ],
    function( $ )  {

        var module = {
            userLoaded : $.Callbacks("unique"),
            showSettings : $.Callbacks("unique"),
            sortClicked : $.Callbacks("unique"),
            tableDataLoaded : $.Callbacks("unique"),
            layoutChange : $.Callbacks("unique"),
            navigateAfterLogin : $.Callbacks("unique")
        };

        return module;
    }
);



