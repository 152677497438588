define(
    'viewmodels/modals/issues_select',[
        "plugins/dialog",
        "knockout",
        "base/forms",
        "contexts/context",
        'models/model',
        "i18next",
        "jquery"
    ],
    function (dialog, ko, forms, context, dataModel, i18n, $) {
        "use strict";

        var IssueModal = function (issue, parentIssueId) {
            var self = this;

            self.parentIssueId = parentIssueId;
            self.issue = issue;

            self.selectedIssue = ko.observable(issue());
            self.selectedIssueId = ko.observable(issue() ? (typeof  issue().id === "number" ? issue().id : issue().id()) : -1);

            self.selectIssue = function (data) {
                self.selectedIssue(data);
                self.selectedIssueId(data.id());
            };

            self.cancel = function () {
                dialog.close(self, {issue: null});
            };

            self.ok = function () {
                this.issue(this.selectedIssue());
                dialog.close(this, {issue: this.selectedIssue()});
            };

            self.page = new dataModel.Pagination({
                limit: 25,
                loader: context.admin.getIssues
            });
            
            self.tableHeight = ko.observable('500px');
            self.activate = function() {
                var height = $(window).height() - 300;                
                self.tableHeight( height + 'px' );
            };
        };

        IssueModal.show = function (issue, id) {
            return dialog.show(new IssueModal(issue, id));
        };

        return IssueModal;
    });
