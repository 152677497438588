define('base/notifications',
    [ 'jquery' ],
    function( $ )  {
        var module = {
            eventListeners : {},
            EVENTS : {
                USER_LOADED : "user.loaded"
            }
        };
        
        module.on = function(eventType, handler) {
            if( !module.eventListeners.hasOwnProperty(eventType) ) {
                module.eventListeners[eventType] = [];
            }            
            for( var i = 0; i < module.eventListeners[eventType].length; i++) {
                if(module.eventListeners[eventType][i] === handler) {
                    return;
                }
            }
            module.eventListeners[eventType].push(handler);
        };
        
        module.dispatch = function( eventType, params ) {
            if( module.eventListeners.hasOwnProperty(eventType) ) {                
                for( var i = 0; i < module.eventListeners[eventType].length; i++ ) {
                    if( $.isFunction( module.eventListeners[eventType][i] ) ) {
                        module.eventListeners[eventType][i](params);
                    }
                }
            }
        };
        
        return module;
    }
);



