define('base/customPlugins',
    [
        'jquery',
        'knockout'
    ],
    function ($, ko) {
        "use strict";

        $.fn.sortElements = (function () {
            var sort = [].sort;
            return function (comparator, getSortable) {
                getSortable = getSortable || function () {
                    return this;
                };
                var placements = this.map(function () {
                    var sortElement = getSortable.call(this),
                        parentNode = sortElement.parentNode,
                        nextSibling = parentNode.insertBefore(document.createTextNode(""), sortElement.nextSibling);

                    return function () {
                        if (parentNode === this) {
                            throw new Error("No nested elements allowed!");
                        }
                        parentNode.insertBefore(this, nextSibling);
                        parentNode.removeChild(nextSibling);
                    };
                });

                return sort.call(this, comparator).each(function (i) {
                    placements[i].call(getSortable.call(this));
                });
            };
        }());
        
        $.fn.center = function(parent, scrollCalc) {
            if (parent) {
                parent = this.parent();
            } else {
                parent = window;
            }
            this.css({                
                "top": ( ( ( ( $(parent).height() - this.outerHeight() ) / 2 ) - ( scrollCalc ? $(parent).scrollTop() : 0 ) ) + "px" ),
                "left": ( ( ( ( $(parent).width() - this.outerWidth() ) / 2 ) - ( scrollCalc ? $(parent).scrollLeft() : 0 ) ) + "px" )
            });
            return this;
        };

        var mapProperty = function (raw, mappingOptions) {
            if (raw != null && typeof raw === 'object') {
                return fromJS(raw, mappingOptions, {});
            } else {
                return ko.observable(raw);
            }
        };

        var fromJS = function (raw, mappingOptions, target) {
            mappingOptions = mappingOptions || {};
            target = target || {};
            for (var property in raw) {
                if (raw[property] instanceof Array) {
                    var createItem = function (options) {
                        return mapProperty(options.data, mappingOptions);
                    };
                    if (mappingOptions[property]) {
                        createItem = mappingOptions[property].create;
                    }
                    target[property] = ko.observableArray(raw[property].map(function (item) {
                        return createItem({ data: item });
                    }));
                } else {
                    target[property] = mapProperty(raw[property], mappingOptions);
                }
            }
            return target;
        };

        ko.mapper = {
            fromJS: fromJS,
            mapProperty: mapProperty
        };
        
        (function() {
            var $dragging = null;
            var resizeMode = null;            
            var moveMode = false;                      
            
            $(document.body).on("mousemove", function(e) {
                if ($dragging) {
                    if (moveMode) {                        
                        $dragging.target.offset({
                            top: e.pageY - $dragging.downY,
                            left: e.pageX - $dragging.downX
                        });                        
                    } else
                    if (resizeMode === 'nwse') {
                        $dragging.target.width(e.pageX - $dragging.target.offset().left + $dragging.downX + 10);
                        $dragging.target.height(e.pageY - $dragging.target.offset().top + $dragging.downY + 10);
                    } else
                    if (resizeMode === 'ns') {
                        $dragging.target.height(e.pageY - $dragging.target.offset().top + $dragging.downY + 10);
                    } else
                    if (resizeMode === 'ew') {
                        $dragging.target.width(e.pageX - $dragging.target.offset().left + $dragging.downX + 10);
                    }
                } else {  
                    moveMode = false;
                    resizeMode = null;
                    if ($(e.target).hasClass('modal-header')) {
                        if($(e.target).closest('.modal-content').attr('move') !== 'on' ) {
                            return;
                        }
                        var header = $(e.target);
                        moveMode = e.offsetX < header.width() - 50;
                        header.css('cursor', moveMode ? 'move' : '');
                    } else
                    if ($(e.target).hasClass('modal-footer')) {
                        if($(e.target).closest('.modal-content').attr('move') !== 'on' ) {
                            return;
                        }
                        var footer = $(e.target);                        
                        if( e.offsetY > footer.height() - 5 ) {
                            if( e.offsetX > footer.width() - 5 ) {
                                resizeMode = 'nwse';                                
                            } else {
                                resizeMode = 'ns';                                
                            }
                        } else
                        if( e.offsetX > footer.width() - 5 ) {
                            resizeMode = 'ew';                            
                        }                                            
                        footer.css('cursor', resizeMode ? resizeMode + '-resize' : '');                        
                    } else
                    if ($(e.target).hasClass('modal-body')) {
                        if($(e.target).closest('.modal-content').attr('move') !== 'on' ) {
                            return;
                        }
                        var modalbody = $(e.target);
                        if( e.offsetX > modalbody.width() - 5 ) {
                            resizeMode = 'ew';                            
                        }
                        modalbody.css('cursor', resizeMode ? resizeMode + '-resize' : '');
                    }
                }
            });

            $(document.body).on("mousedown", ".modal-header,.modal-footer,.modal-body", function(e) {
                if ($dragging) {
                    return;
                }
                var dragElement = $(e.target);
                var dialog = dragElement.closest('.modal-content');

                if (moveMode) {
                    $dragging = {target: dialog,
                        downX: e.offsetX,
                        downY: e.offsetY};
                } else
                if (resizeMode) {
                    $dragging = {target: dialog,
                        downX: dragElement.width() - e.offsetX,
                        downY: dragElement.height() - e.offsetY};
                }
            });

            $(document.body).on("mouseup", function(e) {
                $dragging = null;                
            });              
        }());
    });
