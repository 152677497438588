define('models/pagination',
        [
            'knockout',
            'jquery',
            'moment',
            'viewmodels/modals/filter_states',
            'base/callbacks',
            'i18next',
            'contexts/context'
        ],
        function (ko, $, moment, filterStatesModal, callbacks, i18n, context) {
            "use strict";

            var module = {};

            var selectedSupplier = ko.observable("");
            var suppliers = ko.observableArray([]);
            var userSuppliers = ko.observableArray([]);
            var filterSupplierVisible = ko.observable(false);
            var oldestIssueDateOfUser = '2000-01-01';
			
			/**var limitBefore = null,
							offsetBefore = null,
                            searchtermBefore = null,
                            filterBefore = null,
                            orderBefore = null,
                            ascBefore = null;**/

            function getDateBySupplierId(supplierId) {
                if(supplierId === -1000) {
                    return '2000-01-01';
                }
                
                var splrs = suppliers();
                for (var i = 0; i < splrs.length; i++) {
                    if (splrs[i].id === supplierId) {
                        return splrs[i].first;
                    }
                }
                return null;
            }
            
            function checkSourceList(user) {
            	return user && user.userRole === "admin" 
            		        && user.hasOwnProperty("sourcesList")
            		        && $.isArray(user.sourcesList)
            		        && user.sourcesList.length > 1;
            }
            
            function checkSupplierList(user) {
            	return user && user.hasOwnProperty("suppliers")
            				&& $.isArray(user.suppliers)
            				&& user.suppliers.length > 1;
            }

            function userLoaded(user) {
                selectedSupplier("");
                
                var sourceGroup = [
                    {
                    	id: 'group',
                    	label: i18n.t("app:labels.source")
                    }             
                ];

                var brandsGroup = [
                    {
                        id: 'group',
                        label: i18n.t("app:labels.my_ticket_brands")
                    }
                ];
                
                var brandsGroupAssigned = [
                    {
                        id: 'group',
                        label: i18n.t("app:labels.all_ticket_brands")
                    }
                ];

                var specialFilterGroup = [
                    {
                        id: 'group',
                        label: i18n.t("app:labels.special_filter")
                    }
                ];

                var noFilterOption = [
                    {
                        id: "",
                        label: i18n.t("app:labels.no_filter_or_select"),
                        count: ""
                    }
                ];

                if (user
                		&& (checkSupplierList(user) || checkSourceList(user))) {
                	
                    var splrs = noFilterOption;
                    
                    if (checkSourceList(user)) {
                    	splrs = splrs.concat(sourceGroup)
                    		 		 .concat(user.sourcesList);
                    }
                    
                    if (checkSupplierList(user)) {
                    	splrs = splrs.concat(brandsGroup)
                    				 .concat(user.suppliers);
                    }
                    
                    if(user.userRole === "editor") {
                        var splrsAss = [];
                        var allAss = $.extend(true, {}, user.suppliers[0]);
                        allAss.id = -1000;
                        allAss.label = i18n.t("app:labels.all");
                        allAss.count = '';
                        
                        splrsAss.push( allAss );                        
                        var splrsNull = [];
                        for(var i = 0; i < user.suppliers.length; i++) {
                            var sA = $.extend(true, {}, user.suppliers[i]);
                            sA.id = - 1000 - sA.id;
                            sA.count = sA.countAssigned;
                            sA.first = sA.firstAssigned;
                            sA.label = sA.labelAssigned;
                            
                            if( sA.count === 0 ) {
                                splrsNull.push(sA);
                            } else {                            
                                splrsAss.push(sA);
                            }
                        }
                        
                        splrs = splrs
                                    .concat(brandsGroupAssigned)
                                    .concat(splrsAss)
                                    .concat(splrsNull);
                    }

                    if (splrs.length > 1) {
                    	filterSupplierVisible(true);
                    	suppliers(splrs);
                    } else {
                    	filterSupplierVisible(false);
                    	suppliers([]);
                    }
                } else {
                    filterSupplierVisible(false);
                    suppliers([]);
                }
                
                if (user && user.hasOwnProperty("adminInfos")
                        && user.adminInfos !== null
                        && $.isArray(user.adminInfos.brandsPerUser)
                        && user.adminInfos.brandsPerUser.length > 0) {
                    var brandsPerUser = noFilterOption
                            .concat(specialFilterGroup)
                            .concat([{id: "-1",
                                    label: "- " + i18n.t("app:labels.inactive"),
                                    count: ""},
                                {id: "-2",
                                    label: "- " + i18n.t("app:labels.issuer"),
                                    count: ""},
                                {id: "-3",
                                    label: "- " + i18n.t("app:labels.editor"),
                                    count: ""},
                                {id: "-4",
                                    label: "- " + i18n.t("app:labels.admin"),
                                    count: ""}])
                            .concat(brandsGroup)
                            .concat(user.adminInfos.brandsPerUser);
                    userSuppliers(brandsPerUser);
                    
                    if (user.hasOwnProperty("oldestIssueDate") 
                            && user.oldestIssueDate
                              && $.trim(user.oldestIssueDate) !== '') {
                    	oldestIssueDateOfUser = user.oldestIssueDate;
                    }
                } else {
                    userSuppliers([]);
                    
                    if(user 
                         && user.hasOwnProperty("oldestIssueDateOfUser")
                            && user.oldestIssueDateOfUser
                              && $.trim(user.oldestIssueDateOfUser) !== '') {
                    	oldestIssueDateOfUser = user.oldestIssueDateOfUser;
                    }
                }                
            };

            callbacks.userLoaded.add(userLoaded);

            module.Model = function (options) {

                var self = this,
                        opts = options || {};

                self.isNil = options.isNil || false;
                self.list = ko.observableArray([]);
                self.input_search = ko.observable(opts.searchterm || "");
                self.searchterm = ko.observable(opts.searchterm || "");

                self.filterActive = ko.observable(opts.filterActive || false);
                self.filterSupplier = ko.observable(opts.filterSupplier || false);

                self.backupStartDate = moment().subtract(14, 'days').format("L");
                self.backupEndDate = moment().add(1, 'days').format("L");

                self.filterStartDate = ko.observable(self.backupStartDate).extend({
                    validate: {name: "filterStartDate", type: "datepicker", checks: [""], isPageControl: true}
                });
                self.filterEndDate = ko.observable(self.backupEndDate).extend({
                    validate: {name: "filterEndDate", type: "datepicker", checks: [""], isPageControl: true}
                });
                self.filterStates = ko.observableArray([0, 1, 2, 3, 4, 5, 6, 7]);

                self.suppliers = self.filterSupplier() === 2 ? userSuppliers : suppliers;

                self.selectedSupplier = selectedSupplier;
                self.filterSupplierVisible = filterSupplierVisible;

                self.filterSupplierVisible = ko.computed(function () {
                    return self.filterSupplierVisible() && self.filterSupplier();
                }, self);

                self.limit = ko.observable(opts.limit || 10);
                self.offset = ko.observable(opts.offset || 0);
                self.total = ko.observable(0);
                self.order = ko.observable("");
                self.asc = ko.observable("1");

                self.currPage = ko.observable(1);
                self.numPages = ko.observable(1);

                self.showFirst = ko.observable(false);
                self.showSecond = ko.observable(false);
                self.showPrevDivider = ko.observable(false);
                self.showSecondPrev = ko.observable(false);
                self.showPrev = ko.observable(false);
                self.showNext = ko.observable(false);
                self.showSecondNext = ko.observable(false);
                self.showNextDivider = ko.observable(false);
                self.showSecondLast = ko.observable(false);
                self.showLast = ko.observable(false);

                self.textFirstPage = ko.observable("1");
                self.textSecondPage = ko.observable("2");
                self.textSecondPrevPage = ko.observable("p-2");
                self.textPrevPage = ko.observable("p-1");
                self.textCurrPage = ko.observable("p");
                self.textNextPage = ko.observable("p+1");
                self.textSecondNextPage = ko.observable("p+2");
                self.textSecondLastPage = ko.observable("n-1");
                self.textLastPage = ko.observable("n");

                self.lastSupplierId = "";

                self.setStartDate = function (date, filter) {
                    self.filterStartDate(moment(date).format("L"));
                    self.filterEndDate(moment().add(1, 'days').format("L"));

                    if (filter) {
                        filter.createdFrom = date;
                        filter.createdTo = moment(self.filterEndDate(), "L").format("YYYY-MM-DD");
                    }
                };

                self.selectOldest = function () {
                    self.setStartDate(oldestIssueDateOfUser);
                };

                self.filter = ko.computed(function () {
                    var filter = {};
                    if (self.filterActive()) {
                        filter.createdFrom = moment(self.filterStartDate(), "L").format("YYYY-MM-DD");
                        filter.createdTo = moment(self.filterEndDate(), "L").format("YYYY-MM-DD");
                        filter.status = self.filterStates().join(",");
                        filter.supplierId = "";
                        filter.sourceId = "";
                        
                        if ($.isNumeric(self.selectedSupplier())) {
                            filter.supplierId = self.selectedSupplier();
                        } else
                        if (!$.isNumeric(self.lastSupplierId)) {
                            self.backupStartDate = filter.createdFrom;
                            self.backupEndDate = filter.createdTo;
                        }

                        if (filter.supplierId !== self.lastSupplierId) {
                            self.lastSupplierId = filter.supplierId;
                            
                            var supplierId = filter.supplierId;
                            
                            if(filter.supplierId <= -1000) {
                                filter.allMeAssignedSuppliers = "1";
                                if( filter.supplierId === -1000 ) {
                                    filter.supplierId = "";
                                } else {
                                    filter.supplierId = filter.supplierId * -1 - 1000;
                                }
                            }
                            
                            if (supplierId) {
                                if (filter.supplierId > 0 || supplierId === -1000) {
                                    var first = getDateBySupplierId(supplierId);
                                    if (first) {
                                        self.setStartDate(first, filter);
                                    }
                                }
                            } else {
                                filter.createdFrom = self.backupStartDate;
                                filter.createdTo = self.backupEndDate;
                                self.filterStartDate(moment(self.backupStartDate).format("L"));
                                self.filterEndDate(moment(self.backupEndDate).format("L"));
                            }
                        }
                        
                        if (!$.isNumeric(self.selectedSupplier()) && self.selectedSupplier() != i18n.t("app:labels.no_filter_or_select")) {
                        	filter.sourceId = self.selectedSupplier();
                        	
                        	if (filter.sourceId) {
                        		var first = getDateBySupplierId(filter.sourceId);
                        		if (first) {
                        			self.setStartDate(first, filter);
                        		}
                        	}
                        }
                    } else
                    if (self.filterSupplier()) {
                        filter.supplierId = "";
                        if ($.isNumeric(self.selectedSupplier())) {
                            filter.supplierId = self.selectedSupplier();
                        }
                    }
                    return filter;
                }, self).extend({rateLimit: 100});

                self.load = function (offset, limit, searchterm, order, asc, filter) {
                    var o = parseInt(offset || self.offset(), 10),
                            l = parseInt(limit || self.limit(), 10),
                            s = searchterm || self.searchterm(),
                            f = filter || self.filter(),
                            r = order || self.order(),
                            a = asc || self.asc();

                    if (opts.loader) {
                        opts.loader(o, l, s, r, a, f)
                                .then(function (data) {
                                    self.total(data.totalCount || 0);
                                    self.list(data.resultsPage || []);
                                })
                                .fail(function (msg) {
                                    self.total(0);
                                    self.list([]);
                                });
                    }
                    return self;
                };

                self.autoResetOnSearchAndFilter = ko.computed(function () {
                    var filter = self.filter(),
                            searchterm = self.searchterm(),
                            order = self.order(),
                            asc = self.asc();
                    if (opts.loader) {
                        self.offset(0);
                    }
                }, self).extend({rateLimit: 10});

                self.autoLoader = ko.computed(function () {
					
					var limit = parseInt(self.limit(), 10),
							offset = parseInt(self.offset(), 10),
							searchterm = self.searchterm(),
							filter = self.filter(),
							order = self.order(),
							asc = self.asc();
							
					/**if ((limitBefore == null && offsetBefore == null && searchtermBefore == null &&
						 filterBefore == null && orderBefore == null && ascBefore == null) ||
						limitBefore != limit || offsetBefore != offset || searchtermBefore != searchterm ||
						filterBefore.createdFrom != filter.createdFrom || filterBefore.createdTo != filter.createdTo ||
						filterBefore.sourceId != filter.sourceId || filterBefore.status != filter.status || 
						filterBefore.supplierId != filter.supplierId || orderBefore != order || ascBefore != asc ) {	
						
						limitBefore = limit;
						offsetBefore = offset;
						searchtermBefore = searchterm;
						filterBefore = filter;
						orderBefore = order;
						ascBefore = asc;**/

						self.load(offset, limit, searchterm, order, asc, filter);
					//}
                }, self).extend({rateLimit: 10});

                self.orderBy = function (field, asc) {
                    self.order(field);
                    self.asc(asc);
                };
                self.removeItemByKeyValue = function (key, value) {
                    self.list($.grep(self.list(), function (item) {
                        return ko.unwrap(item[key]) !== value;
                    }));
                };

                self.calc = ko.computed(function () {
                    var l = parseInt(self.limit(), 10),
                            o = parseInt(self.offset(), 10),
                            t = parseInt(self.total(), 10),
                            p = l > 0 ? (o / l >> 0) + 1 : 1,
                            n = l > 0 ? ((t - 1) / l >> 0) + 1 : 1;

                    self.currPage(p);
                    self.numPages(n);
					
					var preDivider = (p - 3 >= 1);
					var nextDivider = (p <= n) && (p + 3 <= n);
                    
                    self.showFirst((p - 3 >= 1));
                    self.showSecond((p - 4 >= 1) && !preDivider);
                    self.showPrevDivider(preDivider);
                    self.showSecondPrev(p - 2 >= 1 && !preDivider);
                    self.showPrev(p - 1 >= 1);
                    self.showNext(p + 1 <= n);
                    self.showSecondNext((p <= n) && (p + 2 <= n) && !nextDivider);
					
					self.showNextDivider(nextDivider);
                    self.showSecondLast(((p <= n) && (p + 4 <= n)) && !nextDivider) ;
                    self.showLast((p <= n) && (p + 3 <= n));

                    self.textSecondPrevPage("" + (p - 2));
                    self.textPrevPage("" + (p - 1));
                    self.textCurrPage("" + (p));
                    self.textNextPage("" + (p + 1));
                    self.textSecondNextPage("" + (p + 2));
                    self.textSecondLastPage("" + (n - 1));
                    self.textLastPage("" + (n));
                }, self).extend({rateLimit: 10});
            };

            module.Model.prototype.openStateFilter = function () {
                var self = this;
                filterStatesModal.show({filter: self.filterStates()})
                        .then(function (settings) {
                            if (settings) {
                                self.filterStates(settings.filter);
                            }
                        });
            };

            module.Model.prototype.doSearch = function () {
                // console.log("Search" + this.input_search());
                this.searchterm(this.input_search());
            };

            module.Model.prototype.gotoFirstPage = function () {
                this.offset(0);
            };

            module.Model.prototype.gotoSecondPage = function () {
                var l = parseInt(this.limit(), 10);
                this.offset(l);
            };
            module.Model.prototype.gotoSecondPrevPage = function () {
                var l = parseInt(this.limit(), 10),
                        p = parseInt(this.currPage(), 10);
                this.offset((p - 3) * l);
            };

            module.Model.prototype.gotoPrevPage = function () {
                var l = parseInt(this.limit(), 10),
                        p = parseInt(this.currPage(), 10);
                if (this.showPrev()) {
                    this.offset((p - 2) * l);
                }
            };

            module.Model.prototype.gotoNextPage = function () {
                var l = parseInt(this.limit(), 10),
                        p = parseInt(this.currPage(), 10);
                if (this.showNext()) {
                    this.offset(p * l);
                }
            };

            module.Model.prototype.gotoSecondNextPage = function () {
                var l = parseInt(this.limit(), 10),
                        p = parseInt(this.currPage(), 10);
                this.offset((p + 1) * l);
            };

            module.Model.prototype.gotoSecondLastPage = function () {
                var l = parseInt(this.limit(), 10),
                        n = parseInt(this.numPages(), 10);
                this.offset((n - 2) * l);
            };

            module.Model.prototype.gotoLastPage = function () {
                var l = parseInt(this.limit(), 10),
                        n = parseInt(this.numPages(), 10);
                this.offset((n - 1) * l);
            };

            module.Model.Nil = new module.Model({isNil: true});

            return module;

        }
)
        ;
