define('base/formBindings',
    [
        'knockout',
        'durandal/composition',
        'underscore',
        'i18next',
        'jquery',
        'moment',
        'chosen'
    ],
    function (ko, composition, _, i18n, $, moment, chosen) {
        "use strict";
        
        function calcDatePickerPos( input ) {            
            var inputPosY = $(input).offset().top;
            var inputHeight = $(input).outerHeight(true);
            var scrollTop = $(window).scrollTop();
            var yPos = inputPosY + inputHeight - scrollTop;
            if( yPos < 86 ) {
                yPos = 86;
            } 
            return yPos;
        }
        
        var module = {},
            HTML5_INPUT_TYPES = ["text", "password", "datetime", "datetime-local", "date", "month", "time", "week", "number", "email", "url", "search", "tel", "color", "checkbox", "radio"];

        ko.extenders.validate = function (target, options) {
            var opts = $.extend({
                isSubmitted: false,
                msgKey: options.name || "value",
                checks: [],
                message: ""
            }, options || {});

            target.controlName = options.name || "-control-" + Math.floor((Math.random() * 100000) + 1);
            target.controlType = options.type || "text";
            target.controlLabel = options.label || undefined;
            target.isSubmitted = ko.observable(opts.isSubmitted);
            target.hasError = ko.observable(false);
            target.validationMessage = ko.observable("");
            target.lastSubmitTime = ko.observable("");
            target.maxlength = options.maxlength || undefined;
            target.rows = options.rows || undefined;
            target.isPageControl = options.isPageControl || undefined;

            function validate(newValue) {
                if (target.isSubmitted()) {                    
                    if (_.contains(opts.checks, "required") && !newValue) {
                        target.validationMessage(ko.unwrap(opts.message) || i18n.t("app:validation." + opts.msgKey, {context: "required"}))
                            .hasError(true);
                    } else if (_.contains(opts.checks, "percentage")) {
                        var val = Number(newValue);
                        if (!(isNaN(val) && val >= 0 && val <= 100)) {
                            target.validationMessage(ko.unwrap(opts.message) || i18n.t("app:validation." + opts.msgKey, {context: "percentage"}))
                                .hasError(true);
                        }
                    } else if( _.contains(opts.checks, "match") && newValue !== opts.matchField() ) {
                        target.validationMessage(ko.unwrap(opts.matchErrorMessage) || i18n.t("app:validation." + opts.msgKey, {context: "match"}))
                                .hasError(true);
                    } else if( _.contains(opts.checks, "pattern") && !newValue.match(opts.pattern) ) {
                        target.validationMessage(ko.unwrap(opts.patternErrorMessage) || i18n.t("app:validation." + opts.msgKey, {context: "pattern"}))
                                .hasError(true);
                    } else {
                        target.validationMessage("")
                            .hasError(false);
                    }
                }
            }

            ko.computed(function () {
                var submitted = target.isSubmitted();
                var lastSubmitTime = target.lastSubmitTime();
                var val = target();
                validate(this());
            }, target);

            validate(target());
            target.subscribe(validate);
            return target;
        };

        ko.extenders.selectOptions = function (target, options) {

            target.options = ko.observableArray(options.options || []);
            target.optionsText = options.optionsText || undefined;
            target.optionsValue = options.optionsValue || undefined;

            return target;
        };

        ko.bindingHandlers.inputControl = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var $el = $(element),
                    val = valueAccessor(),
                    $control = "",
                    $errorMsg = $("<p>").addClass("help-block").css("display", "none");

                if (_.contains(HTML5_INPUT_TYPES, val.controlType || "text")) {
                    $control = $("<input>")
                        .attr({
                            "id": val.controlName,
                            "name": val.controlName,
                            "type": val.controlType || "text",
                            "maxlength": val.maxlength,
                            "placeholder": i18n.t("app:labels." + val.controlName, {context: "placeholder"})
                        }).addClass("form-control").addClass($el.data("control-class"));
                    ko.applyBindingsToNode($control.get(0), {value: val});

                } else if ("select" === val.controlType || "chosen" === val.controlType) {
                    $control = $("<select>")
                        .attr({
                            "id": val.controlName,
                            "name": val.controlName
                        }).addClass("form-control").addClass($el.data("control-class"));                                        
                                                            
                    if( "chosen" === val.controlType ) {
                        $control.attr({ "multiple": 'multiple' })
                                .addClass("chosen-select"); 
                        ko.applyBindingsToNode($control.get(0), {
                            options: val.options || [],  // altern. allBindingsAccessor!
                            optionsText: val.optionsText || undefined,
                            optionsValue: val.optionsValue || undefined,                            
                            selectedOptions: val
                        });                                                
                    } else {                                                            
                        ko.applyBindingsToNode($control.get(0), {
                            options: val.options || [],  // altern. allBindingsAccessor!
                            optionsText: val.optionsText || undefined,
                            optionsValue: val.optionsValue || undefined,
                            optionsCaption: i18n.t("app:labels.choose"),
                            value: val
                        });                        
                    }
                } else if ("textarea" === val.controlType) {
                    $control = $("<textarea>")
                        .attr({
                            "id": val.controlName,
                            "name": val.controlName,
                            "rows": val.rows || 3,
                            "maxlength": val.maxlength,
                            "placeholder": i18n.t("app:labels." + val.controlName, {context: "placeholder"})
                        }).addClass("form-control").addClass($el.data("control-class"));
                    ko.applyBindingsToNode($control.get(0), {value: val});
                } else if ("datepicker" === val.controlType) {
                    $control = $("<input>")
                        .attr({
                            "id": val.controlName,
                            "name": val.controlName,
                            "type": val.controlType || "text"
                        }).addClass("form-control").addClass($el.data("control-class"));
                    ko.applyBindingsToNode($control.get(0), {value: val});
                } else {
                    // console.log("Could not process inputControl: " + JSON.stringify({ctrl: val}));
                }
                if ($control) {
                    $el.append($control);
                    if ("datepicker" === val.controlType) {
                        $el.addClass("input-group input-group-sm date");
                        $el.append($("<span>").addClass("input-group-addon").append($("<i>").addClass("fa fa-calendar")));
                        
                        var dpParams = { todayBtn: "linked",
                            format: moment.localeData().longDateFormat('L').toLowerCase(),
                            language: moment.locale(),
                            calendarWeeks: true,
                            autoclose: true,
                            todayHighlight: true };
                        
                        if( val.isPageControl ) {
                            dpParams.container = '#pageControls';
                            dpParams.orientation = 'top';
                            $el.datepicker(dpParams).on('show', function() {                                                    
                                $('.datepicker-dropdown').css({top: calcDatePickerPos(this) + 'px'});
                            });
                        } else {
                            $el.datepicker(dpParams);
                        }
                        
                        window.addEventListener('resize', function() {
                            $el.datepicker('hide');
                        });
                    }
                    if( "chosen" === val.controlType ) {
                        $control.chosen({width:'100%'});                       
                    }
                    
                    if (_.isFunction(val.hasError)) {
                        $el.append($errorMsg);
                        
                        val.setErrorState = function(newErrorState) {
                            if (newErrorState) {
                                $el.addClass("has-error");
                                $errorMsg.html(val.validationMessage()).removeAttr("style");
                            } else {
                                $el.removeClass("has-error");
                                $errorMsg.css("display", "none");
                            }
                        };
                        
                        val.hasError.subscribe(function (newErrorState) {
                            val.setErrorState(newErrorState);
                        });
                        
                        ko.computed(function() {
                           var lastSubmitTime = val.lastSubmitTime();
                           var value = val();
                           val.setErrorState(val.hasError());
                        });
                    }
                }
            }
        };

        ko.bindingHandlers.checkboxButton = {
            init: function (element, valueAccessor) {
                var $element = $(element);

                if ($element.is("input:checkbox")) {
                    $element.closest(".btn").on("click", function (event) {
                        setTimeout(function () {
                            var value = valueAccessor(),
                                data = $element.val(),
                                isChecked = $element.is(":checked"), //before update!
                                index = ko.unwrap(value).indexOf(data);

                            if (isChecked && (index === -1)) {
                                value.push(data);
                            } else if (!isChecked && (index !== -1)) {
                                value.splice(index, 1);
                            }
                        }, 0);
                    });
                }
            },
            update: function (element, valueAccessor) {
                var $element = $(element);
                if ($element.is("input:checkbox")) {
                    if ($element.is(":checked")) {
                        $element.closest("label").addClass("active");
                    } else {
                        $element.closest("label").removeClass("active");
                    }
                }
            }
        };
        
        ko.bindingHandlers.control = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var $el = $(element),
                    val = valueAccessor(),
                    $label = null,
                    $helpBlock = null,
                    $control = null,
                    $errorMsg = $("<p>").addClass("help-block").css("display", "none");

                if (!$el.is(".form-group")) {
                    if (_.contains(HTML5_INPUT_TYPES, val.controlType || "text")) {

                        $control = $("<input>")
                            .attr({
                                "id": val.controlName,
                                "name": val.controlName,
                                "type": val.controlType || "text",
                                "maxlength": val.maxlength || undefined,
                                "placeholder": i18n.t("app:labels." + val.controlName, {context: "placeholder"})
                            }).addClass("form-control").addClass($el.data("control-class"));
                        ko.applyBindingsToNode($control.get(0), {value: val});

                    } else if ("select" === val.controlType || "chosen" === val.controlType) {

                        $control = $("<select>")
                            .attr({
                                "id": val.controlName,
                                "name": val.controlName
                            }).addClass("form-control").addClass($el.data("control-class"));
                                                                    
                        if( "chosen" === val.controlType ) {
                            $control.attr({ "multiple": 'multiple' })
                                    .addClass("chosen-select");                       
                            ko.applyBindingsToNode($control.get(0), {
                                options: val.options || [],  // altern. allBindingsAccessor!
                                optionsText: val.optionsText || undefined,
                                optionsValue: val.optionsValue || undefined,                            
                                selectedOptions: val
                            }); 
                        } else {                            
                            ko.applyBindingsToNode($control.get(0), {
                                options: val.options || [],  // altern. allBindingsAccessor!
                                optionsText: val.optionsText || undefined,
                                optionsValue: val.optionsValue || undefined,
                                optionsCaption: i18n.t("app:labels.choose"),
                                value: val
                            });
                        }
                    } else if ("textarea" === val.controlType) {
                        //<textarea class="form-control" rows="3"></textarea>
                        $control = $("<textarea>")
                            .attr({
                                "id": val.controlName,
                                "name": val.controlName,
                                "rows": val.rows || 3,
                                "maxlength": val.maxlength || undefined,
                                "placeholder": i18n.t("app:labels." + val.controlName, {context: "placeholder"})
                            }).addClass("form-control").addClass($el.data("control-class"));
                        ko.applyBindingsToNode($control.get(0), {value: val});
                    }

                    if (val.controlLabel) {
                        $label = $("<label>").attr("for", val.controlLabel).append(i18n.t("app:labels." + val.controlLabel));
                    }
                    if (val.helpBlock) {
                        $helpBlock = $("<span>").attr("class", "help-block").append(val.helpBlock);
                    }

                    if ($control) {
                        if ($label) {
                            if (val.controlType === 'checkbox') {
                                $label.prepend($control);
                                $el.addClass("checkbox").append($label).append($errorMsg);
                            } else if (val.controlType === 'radio') {
                                $label.prepend($control);
                                $el.addClass("radio").append($label).append($errorMsg);
                            } else {
                                $el.addClass("form-group").append($label).append($control).append($errorMsg);
                            }
                        } else {
                            $el.addClass("form-group").append($control).append($errorMsg);
                        }
                        if ($helpBlock) {
                            $el.append($helpBlock);
                        }
                    }

                    val.hasError.subscribe(function (newErrorState) {
                        if (newErrorState) {
                            $el.addClass("has-error");
                            $errorMsg.html(val.validationMessage()).removeAttr("style");
                        } else {
                            $el.removeClass("has-error");
                            $errorMsg.css("display", "none");
                        }
                    });
                }
            }
        };


        return module;
    });
