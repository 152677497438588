define('dataservices/user',
    [
        'amplify',
        'underscore'
    ],
    function (amplify, _) {
        "use strict";

        var module = {};

        module.registerServices = function () {

            amplify.request.define('get_all_users', 'ajax', {
                url: 'user',
                type: 'GET'
            });

            amplify.request.define('get_all_editors', 'ajax', {
                url: 'user/editors/{supplierId}',
                type: 'GET'
            });

            amplify.request.define('get_user_current', 'ajax', {
                url: 'user/current',
                type: 'GET'
            });

            amplify.request.define('get_user_by_name', 'ajax', {
                url: 'user/{username}/',
                type: 'GET'
            });

            amplify.request.define('user_register', 'ajax', {
                url: 'user/register',
                type: 'POST'
            });

            amplify.request.define('user_login', 'ajax', {
                url: 'user/login',
                type: 'POST'
            });

            amplify.request.define('create_user', 'ajax', {
                url: 'user',
                type: 'POST'
            });

            amplify.request.define('activate_user', 'ajax', {
                url: 'user/{username}/activate',
                type: 'PUT'
            });

            amplify.request.define('update_user', 'ajax', {
                url: 'user/{userId}/',
                type: 'PUT'
            });

            amplify.request.define('migrate_user', 'ajax', {
                url: 'user/{userId}/migrate',
                type: 'PUT'
            });

            amplify.request.define('delete_user', 'ajax', {
                url: 'user/delete',
                type: 'DELETE'
            });

            amplify.request.define('account_migration', 'ajax', {
                url: 'user/migration/{userName}/',
                type: 'POST'
            });

            amplify.request.define('user_reset_password', 'ajax', {
                url: 'user/resetpassword',
                type: 'POST'
            });

            amplify.request.define('user_new_password', 'ajax', {
                url: 'user/newpassword',
                type: 'POST'
            });

        };

        module.removeUser = function(username, adminUser, adminPassword) {
            return amplify.request('delete_user', {
                userName : username,
                supervisor : adminUser,
                password : adminPassword
            });
        };

        module.getUsers = function (offset, limit, searchterm, order, asc, filter) {
            return amplify.request('get_all_users', _.extend({
                offset: offset,
                limit: limit,
                searchterm: searchterm,
                order: order,
                asc: asc
            }, filter ) );
        };

        module.getAllUsers = function () {
            return amplify.request('get_all_users', {

            });
        };

        module.getEditors = function ( supplierId ) {
            return amplify.request('get_all_editors', {
               supplierId : supplierId
            });
        };

        module.getUserByName = function (username) {
            return amplify.request('get_user_by_name', {
                username: username
            });
        };

        module.getCurrentUser = function () {
            return amplify.request('get_user_current', {});
        };

        module.saveUser = function (userData, showAccountChange) {
            if (userData.userName) {
                userData.userId = userData.userName; //.replace(/\./g,'_');
                return amplify.request( showAccountChange ? 'migrate_user' : 'update_user', userData);
            }
            return amplify.request('create_user', userData);
        };

        module.activate = function (username) {
            return amplify.request('activate_user', {
                userName: username
            });
        };

        module.login = function (username, password, authKey) {
            return amplify.request('user_login', {
                userName: username,
                password: password,
                authKey: authKey
            });
        };

        module.newPassword = function (token, username, password) {
            return amplify.request('user_new_password', {
                token: token,
                userName: username,
                password: password
            });
        };

        module.register = function (userDto) {
            return amplify.request('user_register', userDto);
        };

        module.resetPassword = function (username) {
            return amplify.request('user_reset_password', {
                userName: username
            });
        };

        return module;
    });

