define('contexts/message',
    [
        'knockout',
        'base/store',
        'dataservices/dataservice',
        'base/presenter'
    ],
    function (ko, store, dataservice, presenter) {
        "use strict";

        var model = {};

        model.getMessagesByIssueId = function (issueId) {
            return dataservice.message.getMessagesByIssueId(issueId).then(function (data) {
                return data;
            });
        };

        model.getMessageById = function (issueId, id) {
            return dataservice.message.getMessageById(issueId, id).then(function (data) {
                return data;
            });
        };

        model.saveMessage = function (issueId, message) {
            var m = ko.toJS(message);
            m.issueId = issueId;
            return dataservice.message.saveMessage(issueId, m).then(function (data) {
                return data;
            });
        };

        return model;
    });
