define('base/sort',
    [
        "knockout"
    ],
    function (ko) {
        "use strict";

        var module = {};

        module.byNameAsc = function (modelA, modelB) {
            var a = ko.utils.unwrapObservable(modelA),
                b = ko.utils.unwrapObservable(modelB);

            return ko.utils.unwrapObservable(a.name) > ko.utils.unwrapObservable(b.name) ? 1 : -1;
        };

        return module;

    });
