define('datamocks/datamock',
    [
        'datamocks/issue',
        'datamocks/supplier',
        'datamocks/user'
    ],
    function (issue, supplier, user) {
        "use strict";

        var module = {};

        module.registerServices = function () {
            issue.registerServices();
            supplier.registerServices();
            user.registerServices();
        };

        return module;
    });
