define('dataservices/message',
    [
        'amplify'
    ],
    function (amplify) {
        "use strict";

        var module = {};

        module.registerServices = function () {

            amplify.request.define('get_messages', 'ajax', {
                url: 'issue/{issueId}/message',
                type: 'GET'
            });

            amplify.request.define('get_message', 'ajax', {
                url: 'issue/{issueId}/message/{id}',
                type: 'GET'
            });

            amplify.request.define('create_message', 'ajax', {
                url: 'issue/{issueId}/message',
                type: 'POST'
            });
        };

        module.getMessagesByIssueId = function (issueId) {
            return amplify.request('get_messages', {
                issueId: issueId
            });
        };

        module.getMessageById = function (issueId, id) {
            return amplify.request('get_message', {
                issueId: issueId,
                id: id
            });
        };

        module.saveMessage = function (issueId, message) {
            return amplify.request('create_message', message);
        };

        return module;
    });
