define('dataservices/supplier',
    [
        'amplify'
    ],
    function (amplify) {
        "use strict";

        var module = {};

        module.registerServices = function () {

            amplify.request.define('get_all_suppliers', 'ajax', {
                url: 'supplier',
                type: 'GET'
            });

            amplify.request.define('get_supplier_by_id', 'ajax', {
                url: 'supplier/{id}',
                type: 'GET'
            });

            amplify.request.define('get_supplier_by_number', 'ajax', {
                url: 'supplier/number/{number}',
                type: 'GET'
            });

            amplify.request.define('get_supplier_users', 'ajax', {
                url: 'supplier/{id}/user',
                type: 'GET'
            });

        };

        module.getSuppliers = function (offset, limit, searchterm) {
            return amplify.request('get_all_suppliers', {
                offset: offset,
                limit: limit,
                searchterm: searchterm
            });
        };

        module.getSupplierById = function (supplierId) {
            return amplify.request('get_supplier_by_id', {
                id: supplierId
            });
        };

        module.getSupplierByNumber = function (number) {
            return amplify.request('get_supplier_by_number', {
                number: number
            });
        };
        module.getSupplierUsers = function (supplierId, offset, limit, searchterm) {
            return amplify.request('get_supplier_users', {
                id: supplierId,
                offset: offset,
                limit: limit,
                searchterm: searchterm
            });
        };

        return module;
    });
