define('models/suppliers',
    [
        'knockout',
        'underscore',
        'jquery'
    ],
    function (ko, _, $) {
        "use strict";
        
        var m = {
            suppliers: ko.observableArray([]),
            supplierIdMap: ko.observable({})
        };                
        
        m.createId = function( numId ) {
            return "id" + numId;
        };
        
        m.hasSuppliers = function() {
            return m.suppliers().length > 0;
        };
        
        m.setSupplierIdsWithName = function( suppliers ) {
            for(var i = 0; i < suppliers.length; i++) {                    
                suppliers[i].idWithName = ko.unwrap( suppliers[i].name ) + " (" + ko.unwrap( suppliers[i].dlnr ) + ")";
            } 
        };
        
        m.setSuppliers = function( suppliers ) {                        
            if( $.isArray(suppliers) ) {
                m.setSupplierIdsWithName(suppliers);
                m.suppliers(suppliers);
                var map = {};
                for( var i = 0; i < suppliers.length; i++ ) {
                    map[ m.createId( ko.unwrap( suppliers[i].id ) ) ] = suppliers[i];
                } 
                m.supplierIdMap(map);  
            }
        };                
                
        m.getSuppliersByIds = function( ids ) {
            if( $.isArray( ids ) && ids.length > 0 ) {
                var res = [];                
                for( var i = 0; i < ids.length; i++ ) {
                    var id = m.createId( ids[i] );                                        
                    if(m.supplierIdMap().hasOwnProperty(id)) {                        
                        res.push( m.supplierIdMap()[id] );                      
                    }
                }
                return res;
            }
            return null;
        };
        
        m.extractSupplierIds = function( suppliers ) {
            var r = [];
            try {
                if( $.isArray(suppliers) ) {
                    for( var i = 0; i < suppliers.length; i++ ) {
                        try {
                            r.push( ko.unwrap( suppliers[i].id ) );
                        } catch( e ) {}
                    }
                }
            } catch( e ) {}
            return r;
        };

        return m;
    });


