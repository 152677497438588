define(
    'viewmodels/users',[
        'durandal/app',
        'jquery',
        'knockout',
        'contexts/context',
        'models/model',
        'base/presenter',
        'i18next',
        'viewmodels/modals/modal',
        'base/callbacks'
    ],
    function (app, $, ko, context, dataModel, presenter, i18n, modal, callbacks) {
        "use strict";

        //var currentUserName = context.session.info().userName || "";

        var module = {};

        module.pageControl = new dataModel.Pagination({
            limit: 50,
            filterActive: false,
            filterSupplier: 2,
            loader: context.admin.getUsers
        });

        module.tableDataLoaded = ko.computed(function() {
            module.pageControl.list();
            callbacks.tableDataLoaded.fire();
        }, module ).extend({rateLimit: 300});

        module.orderBy = function(params) {
            module.pageControl.orderBy( params.order, params.asc );
        };

        module.editUser = function (user) {
            modal.UserModal.show(user, false, function(result) {
                module.handleUserModalClose(result);
            });
        };

        module.attached = function () {
            $(window).trigger("resize");
        };

        module.deactivate = function () {
            context.pageControl(dataModel.Pagination.Nil);
            callbacks.sortClicked.remove(module.orderBy);
        };

        module.canActivate = function () {
            return context.session.requireAccess()
                    && context.session.hasAdminAccess();
        };

        module.handleUserModalClose = function ( result ) {
            if( result && result.userDeleted ) {
                module.pageControl.removeItemByKeyValue( 'userName', result.userDeleted );
            }
        };

        module.activate = function (username) {
            $(window).trigger("resize");
            if (username) {
                context.admin.getUserByName(username)
                    .then(function (user) {
                        return module.editUser(user);
                    })
                    .always(function() {
                        context.pageControl(module.pageControl);
                    });
            } else {
                context.pageControl(module.pageControl);
            }

            callbacks.sortClicked.add(module.orderBy);
            return true;
        };

        return module;
    });
