define(
    'viewmodels/new_issue',[
        'knockout',
        'underscore',
        "plugins/router",
        "contexts/context",
        "i18next",
        'base/presenter',        
        'jquery',
        'base/forms'
    ],
    function (ko, _, router, context, i18n, presenter, $, forms) {
        "use strict";
        
        var val_required = i18n.t('app:validation.value_required');
        var incomplete_text = i18n.t("app:messages.incompletedata");
        
        var module = {
            landId: ko.observable(),
            softwareVersion: ko.observable(),
            dataVersion: ko.observable(),
            sourceId: ko.observable(),
            sourceName: ko.observable(),
            issueTypeId: ko.observable(),
            issueTypeName: ko.observable(),
            issueObjectId: ko.observable(),
            issueObjectName: ko.observable(),
            supplierNo: ko.observable(),
            supplierId: ko.observable(),
            supplierName: ko.observable(),            
            
            classificationId: ko.observable(),
            notValidInfoMessage: ko.observable(incomplete_text),
                                           
            summary: ko.observable("").extend({
                validate: {name: "summary", type: "text", checks: ["required"], 
                            message: val_required,
                            maxlength: 60 }
            }),
            description: ko.observable("").extend({
                validate: {name: "description", type: "textarea", checks: [""],
                           rows: 5, maxlength: 2000 }
            }),

            issueParameters: ko.observableArray().extend({ rateLimit: 50 }),

            allClassifications: ko.observableArray(),
            allowSubmit: ko.observable(true),
			errorFound: ko.observable(false)
        };
        
        module.showError = function( localeKey ) {
            presenter.toast(i18n.t("app:messages." + localeKey ), presenter.LEVEL.DANGER);
            module.notValidInfoMessage(i18n.t("app:messages." + localeKey ));
            module.allowSubmit(false);
			module.errorFound(true);
        };        
        
        module.setQueryParams = function (queryParams) {
            module.landId(queryParams["lkz"] || queryParams["LKZ"]);
            module.softwareVersion(queryParams["softwareVersion"]);
            module.dataVersion(queryParams["dataVersion"]);
            module.sourceId(queryParams["sourceId"]);
            module.issueTypeId(queryParams["errorTypeId"]);
            module.issueObjectId(queryParams["errorObjectId"]);
            module.supplierNo(queryParams["supplierNo"]);
            
            if(module.sourceId() !== null
                   && module.sourceId().length > 30) {
                module.showError("source_id_to_long");
                return;
            }
            
			module.getIssueObject(queryParams, queryParams["errorObjectId"]);                                                     

            context.supplier.getSupplierByNumber(queryParams["supplierNo"])
                .then(function (supplier) {
                    module.supplierId(supplier.id);
                    module.supplierName(supplier.name);
                })
                .fail(function () {
                    module.allowSubmit(false);
					module.errorFound(true);
                });

            context.businessenum.getSourceById(queryParams["sourceId"])
                .then(function (source) {
                    module.sourceName(source.name);
                })
                .fail(function () {                    
                    module.showError("source_unknown");
                });

            context.businessenum.getIssueTypeById(queryParams["errorTypeId"])
                .then(function (issueType) {
                    module.issueTypeName( i18n.t("app:issuetypes." + issueType.id ) );
                })
                .fail(function () {
                    module.allowSubmit(false);
					module.errorFound(true);
                });

            context.businessenum.getClassifications()
                .then(function (classifications) {
					var queryParams = router.activeInstruction().queryParams;
					var classificationId = module.classificationId(); 
					
					for (var i = 0; i < classifications.length; i++) {
						if (classifications[i].parentId) {
							classifications[i].name = i18n.t("app:issueobjects." + classifications[i].parentId );
						} else {
							classifications[i].name = i18n.t("app:issueclassifications." + classifications[i].id );
							
							if (classifications[i].name.indexOf("app:") > -1) {
								classifications[i].name = "";
							}
						}
					}
					
                    module.allClassifications(classifications);
                   
                    if (!classificationId) {
						module.issueObjectId(queryParams["errorObjectId"]);
					}
                   
                    switch (module.issueObjectId()) {
		            	case "D01":
		            		module.classificationId("11"); // IAM Article
		            		break;
		            	case "D02":
		            	case "D03":
		            	case "D04":
		            	case "D05":
		            	case "D06":
		            	case "D07":
		            		module.classificationId("16"); // Vehicle
		            		break;
		            	case "D11":
		            		module.classificationId("18"); // GA
		            		break;
		            	case "D12":
		            		module.classificationId("21"); // OE Article
		            		break;
		            	case "D14":
		            		module.classificationId("39"); // Maintenance
		            		break;
		            	case "D13":
		            	case "D15":
		            	case "D16":
		            		module.classificationId("36"); // Repair Books
		            		break;
		            	case "D10":
		            	case "D17":
		            	case "D18":
		            	case "U":
		            		module.classificationId("09"); // Other
		            		break;
		            	case "D08":
		            	case "D09":
		            		module.classificationId("14"); // Body type
		            		break;
		            	default:
		            		break;
		            }
		            
					module.getIssueObject(queryParams, module.issueObjectId());
					
					if (module.issueObjectId() == "D12") {
						// console.log("D12 - supplier 0");
						context.supplier.getSupplierByNumber(0)
							.then(function (supplier) {
								module.supplierId(supplier.id);
								module.supplierName(supplier.name);
							})
							.fail(function () {
								module.allowSubmit(false);
								module.errorFound(true);
							});
					}
                })
                .fail(function () {
                    module.allowSubmit(false);
					module.errorFound(true);
                });
        };
        
        module.classificationId.subscribe(function (latest) {
        	
        	if (!module.errorFound()) {
        		module.allowSubmit( true );
        	}
        	
			var queryParams = router.activeInstruction().queryParams;
			
			 switch (module.classificationId()) {
				case "01":
				case "02":
				case "03":
				case "10":
				case "12":
				case "13":
				case "15":
				case "16":
				case "17":
					module.issueObjectId("D02"); // Vehicle / Bike
					break;
				case "04":
				case "05":
				case "06":
				case "07":
				case "08":
				case "11":
				case "19":
				case "31":
				case "32":
				case "33":
				case "34":
					module.issueObjectId("D01"); // IAM Article
					break;
				case "18":
				case "28":
					module.issueObjectId("D11"); // GA
					break;
				case "20":
				case "21":
				case "22":
				case "29":
				case "30":
					module.issueObjectId("D12"); // OE Article
					break;
				case "39":
				case "40":
					module.issueObjectId("D14"); // Maintenance Service
					break;
				case "36":
				case "37":
					module.issueObjectId("D16"); // Repair Manual
					break;
				case "09":
				
					// Others / RMI - Missing RMI: D10, D13, D15, D17, D18
					if (queryParams["errorObjectId"] === "D10" ||
					    queryParams["errorObjectId"] === "D13" ||
						queryParams["errorObjectId"] === "D15" ||
						queryParams["errorObjectId"] === "D17" ||
						queryParams["errorObjectId"] === "D18") {
							
						module.issueObjectId(queryParams["errorObjectId"]);
					} else {
						module.issueObjectId("U"); // Others
					}
					
					break;
				case "14":
					// Body type / Body type linkage
					if (queryParams["errorObjectId"] === "D08" ||
					    queryParams["errorObjectId"] === "D09") {
						
						module.issueObjectId(queryParams["errorObjectId"]);
					} else {
						module.issueObjectId("D08"); // Body type
					}
					
					break;
				default:
					module.issueObjectId(queryParams["errorObjectId"]);
					break;
				}
			
				module.getIssueObject(queryParams, module.issueObjectId());
				
				var supplierNo = queryParams["supplierNo"];
				if (module.issueObjectId() == "D12") {
					supplierNo = 0;
				}
						
				context.supplier.getSupplierByNumber(supplierNo)
					.then(function (supplier) {
						module.supplierId(supplier.id);
						module.supplierName(supplier.name);
					})
					.fail(function () {
						module.allowSubmit(false);
						module.errorFound(true);
					});
        }, this);
        
		module.getIssueObject = function (queryParams, objectId)  {
			context.businessenum.getIssueObjectById(objectId)
                .then(function (issueObject) {
                    var hasMissingInfos = false;
                    module.issueObjectName( i18n.t("app:issueobjects." + issueObject.id ));
                    module.issueParameters(_.map( 
                                _.filter( issueObject.parameters || [], 
                                          function(param) { 
                                              return param.mandatory === true 
                                                        || !!queryParams[ param.tag ]; 
                                          } 
                                ) || [], function (param) {
                        if (param.mandatory === true && !queryParams[param.tag]) {
                        	// console.log( "Missing param: " + param.tag );
                            hasMissingInfos = true;
                        }
                        return {
                            parameterId: param.tag,
                            value: queryParams[param.tag] || "-"
                        }
                    }));
                    if (hasMissingInfos) {
                        module.allowSubmit(false);
                    }
                    
                    var optionalCommonParams = ['searchPath', 'searchType'];
                    for( var i = 0; i < optionalCommonParams.length; i++ ) {
                        var key = optionalCommonParams[i];
                        if(!!queryParams[key] && $.trim(queryParams[key]).length) {
                            module.issueParameters.push({
                                    parameterId: key,
                                    value: queryParams[key]
                                });
                        }
                    }   
                }); 
		}

        module.serialize = function () {
            var issue = ko.toJS(module);
            var classificationId = module.classificationId();
            var classifications = ko.toJS(module.allClassifications);

            for (var i = 0; i < classifications.length; i++) {
                if (classificationId === classifications[i].id) {
                    issue.classification = {id: classificationId, name: classifications[i].name};
                    break;
                }
            }
            issue.allClassifications = undefined;
            issue.issueParameters = _.filter(module.issueParameters(), function (param) {
                return param.value && param.value !== "-";
            });
            issue.supplier = {id: module.supplierId(), name: module.supplierName()};
            issue.source = {id: module.sourceId(), name: module.sourceName()};
            issue.issueType = {id: module.issueTypeId(), name: module.issueTypeName()};
            issue.issueObject = {id: module.issueObjectId(), name: module.issueObjectName()};
            return issue;
        };


        module.canActivate = function () {
            return context.session.requireAccess();
        };
                
        module.submited = false;
        
        module.activate = function () {
            module.submited = false;
            module.summary.hasError(false);
            
            var queryParams = router.activeInstruction().queryParams,
                access = context.session.hasAccess();
            if (access) {
                module.setQueryParams(queryParams);
                return true;
            }
            return false;
        };       

        module.allowSubmitAgain = ko.computed(function() {           
            var summary = module.summary();
            if( module.submited ) {
                module.allowSubmit( $.trim( summary ) !== '' );
                        
                if( !module.allowSubmit() ) {
                    module.notValidInfoMessage( incomplete_text );
                }
           }
        }, module ).extend({ rateLimit: 100 });
        
        module.save = function () {                                              
            if (module.allowSubmit()) {                
                if(!forms.checkForm(module, true)) {
                    module.showError('incompletedata');  
                    module.submited = true;
                    return;
                }                
                var issue = module.serialize();  
                
                context.admin.newIssue(issue).done(function (data) {
                    router.navigate("issues/" + data.id);
                });
            }
        };

        return module;
    }
);
