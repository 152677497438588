define('base/presenter',
    [
        'jquery',
        'knockout',
        'toastr',
        'i18next'
    ],
    function ($, ko, toastr, i18n) {
        "use strict";

        var module = {
            blockingQueue: ko.observableArray([]),
            LEVEL: {
                NONE: 0,
                INFO: 1,
                SUCCESS: 2,
                WARNING: 3,
                DANGER: 4
            }
        };

        module.toast = function (i18nId, notification_level, optionalInfo) {
            // For Error / Response Error Object responseMessage
            if( $.isPlainObject(optionalInfo) ) {  
                if( optionalInfo.hasOwnProperty('message') 
                        && $.isPlainObject(optionalInfo.message)) {
                    optionalInfo = ko.unwrap( optionalInfo.message );
                }
                
                if( optionalInfo.hasOwnProperty('responseMessage')) {
                    optionalInfo = optionalInfo.responseMessage;
                } else
                if( optionalInfo.hasOwnProperty('status') ) {
                    var infoString = "<br><br># ";
                        infoString += new Date().toString();
                        infoString += "<br>Http status: " + optionalInfo.status;
                    if( optionalInfo.hasOwnProperty('responseText') ) {
                        infoString += '<br>With message: ' + optionalInfo.responseText;
                    } else
                    if( optionalInfo.hasOwnProperty('error') 
                            && !$.isFunction(optionalInfo.error) ) {
                        infoString += '<br>With message: ' + optionalInfo.error;
                    } 
                    
                    optionalInfo = infoString;                    
                }
            }   
            
            try {
                if( optionalInfo && ( "" + optionalInfo ).indexOf("nested exception") > 0 ) {
                    optionalInfo = "<br><br># " + new Date().toString();
                    optionalInfo += "<br>Internal Server Error";
                }            
            } catch( ex ) {}
                        
            var msg = optionalInfo ? i18n.t(i18nId) + " " + optionalInfo : i18n.t(i18nId);

            switch (notification_level) {
            case module.LEVEL.NONE:
            case module.LEVEL.INFO:
                toastr.info(msg);
                break;
            case module.LEVEL.SUCCESS:
                toastr.success(msg);
                break;
            case module.LEVEL.WARNING:
                toastr.warning(msg);
                break;
            case module.LEVEL.DANGER:
                toastr.error(msg);
                break;
            }
        };
        module.isBlocking = ko.computed(function () {
            return module.blockingQueue().length;
        }, this);

        module.startBlocking = function (taskId, i18nId) {
            module.blockingQueue.push({taskId: taskId, description: i18n.t(i18nId)});
        };

        module.stopBlocking = function (taskId) {
            module.blockingQueue($.grep(module.blockingQueue(), function (item) {
                return item.taskId !== taskId;
            }));
        };

        module.shellAttached = function () {
        };



        return module;
    });

