define(
        'viewmodels/issue',[
            'jquery',
            'contexts/context',
            'knockout',
            'underscore',
            'i18next',
            'viewmodels/modals/modal',
            'plugins/router',
            "plugins/dialog",
            'dataservices/dataservice',
            'base/presenter',
            'base/callbacks',
            'base/config'
        ],
        function ($, context, ko, _, i18n, modal, router, dialog, dataservice, presenter, callbacks, config) {
            "use strict";

            var module = {};

            module.allClassifications = ko.observableArray();

            module.token = ko.observable();

            module.id = ko.observable();
            module.hashCode = null;
            module.number = ko.observable();
            module.summary = ko.observable();
            module.description = ko.observable();
            module.issueObject = ko.observable();
			module.issueObjectId = ko.observable();
            module.issueType = ko.observable();
            module.issueTypeId = ko.observable();
            module.source = ko.observable();
            module.classification = ko.observable();
			module.classificationId = ko.observable();
            module.softwareVersion = ko.observable();
            module.dataVersion = ko.observable();
            module.status = ko.observable();
            module.statusRaw = ko.observable();
            module.supplier = ko.observable();
            module.created = ko.observable();
            module.modified = ko.observable();
            module.modifiedBy = ko.observable();
            module.issuer = ko.observable();
            module.issuerEmail = ko.observable();
            module.issuerLang = ko.observable();
            module.issuerMailto = ko.observable();
            module.editorMailto = ko.observable();
            module.issuerPhone = ko.observable();
            module.issuerPhoneLink = ko.observable();
            module.editor = ko.observable();
            module.editorAssigned = ko.observable();
            module.editorEmail = ko.observable();
            module.editorPhone = ko.observable();
            module.editorPhoneLink = ko.observable();
            module.relatedIssue = ko.observable();
            module.message = ko.observable();
            module.issueRank = ko.observable(0);
			module.issuerUserName = ko.observable();
            module.issuerName = ko.observable();
            module.solutionRank = ko.observable(0);
            module.sidebarWidth = ko.observable("25%");
            module.issueDetailsWidth = ko.observable("75%");
            module.emailLanguage= ko.observable();
			module.additionalParameters = ko.observableArray();

            module.parameters = ko.observableArray();
            module.attachments = ko.observableArray();
            module.messages = ko.observableArray();
            module.hasEditor = ko.observable();
            module.hasEditor(false);
            module.editorName = ko.observable();
            module.shareEmailLink = ko.observable("");
            
            module.saveAllowed = ko.observable();
            module.saveAllowed(true);
            module.isDescTranslatedEnabled = ko.observable();
            module.isDescTranslatedEnabled(true);
            
            module.isRedoDescTranslatedEnabled = ko.observable();
            module.isRedoDescTranslatedEnabled(false);
			
			module.isCommentsTranslatedEnabled = ko.observable();
            module.isCommentsTranslatedEnabled(true);
            module.descriptionWithoutTranslation = ko.observable();
            module.descriptionWithoutTranslation(null);
            
            module.isRedoCommentsTranslatedEnabled = ko.observable();
            module.isRedoCommentsTranslatedEnabled(false);

            module.allEditors = ko.observableArray();
            module.allEditorsForSupplier = [];
            
            module.isCurrentUser = function( userName ) {
                var is = false;
                var currentUser = null;
                if( userName && context.session.info() 
                                  && context.session.info().userName ) {
                    currentUser = context.session.info().userName.toLowerCase();
                    userName = userName.toLowerCase();
                    is = currentUser === userName;
                }
                // console.log('isCurrentUser ? ' + currentUser + ' == ' + userName + " => " + is );
                return is;
            };
            
            module.isStatusAnyOf = function() {
                var currentStatus = module.statusRaw();
                for(var i=0; i<arguments.length; i++) {
                    var status = arguments[i];
                    if( currentStatus === status ) {
                        return true;
                    }
                }
                return false;
            };
            
            module.isIssueInitedForAnswerProcess = function() {
                return module.isStatusAnyOf( "new", "reactivated", "accepted" );
            };
            
            module.isIssueInAnswerProcess = function() {                
                return module.isStatusAnyOf( "assigned", "feedback", "answered" );
            };
            
            module.isIssueInAnswerProcessOrReactivated = function() {
                return module.isIssueInAnswerProcess()
                        || module.isStatusAnyOf( "reactivated" );
            };
            
            module.isIssueInBeginEditState = function() {
                return module.isStatusAnyOf( "accepted", "reactivated" );
            };                        
            
            module.isIssueInStatusVisibleForEditor = function() {
                return module.isIssueInAnswerProcess() || 
                          module.isIssueInBeginEditState();
            };
            
            module.isUserIssuerOfThisIssue = function() {
                if(!module.original) {
                   // console.log("isUserIssuerOfThisIssue() ? module.original is not set");
                   return false;
                }
                var issueUser = module.original.issuerUserName;                
                if( issueUser ) {
                    issueUser = issueUser.toLowerCase();
                    // console.log( "User of Current Issue is: " + issueUser + " (toLowerCase)" );
                    return module.isCurrentUser( issueUser );
                }
                // console.log( "User of Current Issue is: " + issueUser + " (toLowerCase) => result false" );
                return false;
            };                        

            module.filesToUpload = ko.observableArray().extend({rateLimit: 100});
            module.autoUpload = ko.computed(function () {
                if (module.filesToUpload().length) {
                    var uploadSize = _.reduce(module.filesToUpload(), function (memo, file) {
                        return memo + file.size;
                    }, 0);
                    if (uploadSize <= 1024 * context.session.config().issueAttachmentMaxSizeKB) {
                        dataservice.uploadDatafile($("form[name='upload']"), module.id())
                                .then(function () {
                                    module.attachments.removeAll();
                                    context.attachment.getAttachmentsByIssueId(module.id()).then(function (attachments) {
                                        module.attachments(_.map(attachments, function (currentAttachment) {
                                            return {
                                                userId: currentAttachment.userId,
                                                id: currentAttachment.id,
                                                name: currentAttachment.name,
                                                url: module.attachmentUrl(currentAttachment.id),
                                                deletable : ko.computed(function() {                                                                                        
                                                    var closed = module.isClosedState();                                                                                        
                                                    return context.session.hasAdminAccess() || 
                                                           ( !closed && module.isCurrentUser(currentAttachment.userId) );                                            
                                                })
                                            }
                                        }));
                                        module.attachments.valueHasMutated();
                                    });
                                    module.filesToUpload.removeAll();
                                    presenter.toast(i18n.t("app:messages.upload_success"), presenter.LEVEL.SUCCESS);
                                })
                                .fail(function (msg) {
                                    presenter.toast(i18n.t("app:messages.upload_failed"), presenter.LEVEL.DANGER);
                                });
                    } else {
                        module.filesToUpload.removeAll();
                        presenter.toast(i18n.t("app:messages.upload_size_limit"), presenter.LEVEL.DANGER);
                    }
                }
            });
            
            module.isAcceptVisible = ko.computed(function () {
                if (context.session.hasAdminAccess()) {
                    return module.statusRaw() === "new";
                }
                return false;
            });
            
            module.isTranslateVisible = ko.computed(function () {
            	return module.issuerLang() !== context.session.info().lang;
            });
            
            module.isTranslateDescVisible = ko.computed(function () {
            	return module.issuerLang() !== context.session.info().lang && 
            	       module.description() != null && module.description() != undefined && module.description().length > 2;
            });
			
			module.isTranslateCommentsVisible = ko.computed(function () {
            	return module.messages().length > 0;
            });

            module.isRejectVisible = ko.computed(function () {
                if (context.session.hasAdminAccess()) {
                    return module.statusRaw() === "new";
                }
                return false;
            });

            module.isAssignVisible = ko.computed(function () {
                var editors = module.allEditors();                
                var editor = module.editorAssigned();
                
                if (context.session.hasAdminAccess()) {
                	return true;
                } else              
                if (context.session.hasEditorAccess()) {
                	
                	var editorForIssueBrand = false;
                	
                	if (context.session.info().suppliers) {
                		
	                	for(var i=0; i<context.session.info().suppliers.length; i++) {		                		
	                		if (module.supplier() === context.session.info().suppliers[i].name) {
	                			editorForIssueBrand = true;
	                			break;
	                		}
	                	}
                	}
                	
                    return module.isIssueInStatusVisibleForEditor() 
                    		&& editorForIssueBrand
                            	&& editors.length > 0    // have editors ?
                                	&& !( editors.length === 1  // and not is one editor to which is assigned
                                        && ko.unwrap( editors[0].userName ) === editor );
                }
                return false;
            }, module);
            
            module.isAssignButtonVisible = ko.computed(function() {
                var editor = module.editor();
                var editorAssigned = module.editorAssigned();
                var isAssignVisible = module.isAssignVisible();
                return isAssignVisible && editor && editor !== editorAssigned;
            }, module);
            
            module.isClosedState = ko.computed(function() {
                var status = module.statusRaw();                
                return status === "closed" || status === "rejected";
            });
            
            module.allowEdit = ko.computed(function() {
               var status = module.statusRaw();
               if(!module.original) {
                   return false;
               }               
               return (module.isUserIssuerOfThisIssue() && status === "new") || 
               		  (context.session.hasAdminAccess() && status !== "closed");
            });
            
            module.allowSave = ko.computed(function() { 
				if (module.additionalParameters()) {
                	var adds = ko.toJS(module.additionalParameters());
                	
					for (var d = 0; d < adds.length; d++) {
						if (adds[d].value.length === 0) {
							module.saveAllowed(false);
							return;
						}
					}
                }
				
				module.saveAllowed(true);
            }, module);
            
            module.allowParameterChange = ko.computed(function() {
            	return module.allowEdit() && context.session.hasAdminAccess();
            });
            
            module.showParameterText = ko.computed(function() {
            	return !module.allowParameterChange();
            });
            
            module.dropDownIssueTypeVisible = ko.computed(function() {
            	return !module.allowEdit();
            });
            
            module.isCloseVisible = ko.computed(function () {
                var editorAssigned = module.editorAssigned(); // observable if change editor
                
                if (context.session.hasAdminAccess()) {
                	return !module.isClosedState();
                }
                else if( context.session.hasIssuerAccess() ) {
                    return !module.isClosedState();
                } else
                if (context.session.hasEditorAccess() ) {                                       
                    return module.isCurrentUser( editorAssigned )
                                && module.isIssueInAnswerProcessOrReactivated();
                }
                return false;
            });

            module.isReactivateVisible = ko.computed(function () {
                if (context.session.hasEditorAccess() || context.session.hasIssuerAccess() || context.session.hasAdminAccess()) {
                    return module.statusRaw() === "closed";
                }
                return false;
            });

            module.allowIssueRank = ko.computed(function () {
                module.statusRaw(); // for computed event
                if (context.session.hasEditorAccess()) {
                    return module.isIssueInStatusVisibleForEditor();
                }
                return false;
            }, module);

            module.allowSolutionRank = ko.computed(function () {
                var solutionRank = '' + module.solutionRank();
                var currentStatus = module.statusRaw();                
                var isClosedAndNotRanked = currentStatus === "closed" && solutionRank === "0";                
                // console.log( '@issue.js -> allowSolutionRank( solutionRank: '+solutionRank
                //        +',currentStatus: '+currentStatus+' => isClosedAndNotRanked: ' + isClosedAndNotRanked );
                
                if (context.session.hasIssuerAccess()) {
                    return isClosedAndNotRanked;
                } else if (context.session.hasEditorAccess()) {
                    return module.isUserIssuerOfThisIssue() && isClosedAndNotRanked;
                }
                return false;
            }, module);

            module.allowComment = ko.computed(function () {
                var currentStatus = module.statusRaw();
                var issueInitState = module.isIssueInitedForAnswerProcess();
                var isIssueInAnswerProcess = module.isIssueInAnswerProcess();
                
                if (context.session.hasAdminAccess()) {
                    return true; //module.statusRaw() === "new";
                } else if (context.session.hasIssuerAccess()) {
                    
                    return  issueInitState 
                            || isIssueInAnswerProcess;
                    
                } else if (context.session.hasEditorAccess()) {
                    
                    return ( issueInitState && module.isUserIssuerOfThisIssue() ) 
                               || isIssueInAnswerProcess 
                               || currentStatus === "reactivated" ;
                }
                return false;
            }, this);

            module.allowAttachment = ko.computed(function () {
                module.statusRaw();
                module.isIssueInAnswerProcess();
                
                if (module.attachments() && module.attachments().length >= context.session.config().issueAttachmentsMaxCount) {
                    return false; // Limit exceeded
                }
                if (context.session.hasIssuerAccess()) {
                    return module.allowEdit();
                    
                } else if (context.session.hasEditorAccess()) { 
                    return true;
                }
               
                return false;
            }, this);
                    
            module.allowRelatedIssue = ko.computed(function () {
                if (context.session.hasEditorAccess()) {
                    return module.isIssueInAnswerProcessOrReactivated();
                }
                return false;
            }, this);

            module.rankIssue = function (vm, event) {
                if (module.allowIssueRank()) {
                    module.issueRank(Number($(event.target).data("rank")));
                    module.original.issueRank = module.issueRank();
					module.original.classification.id = module.classificationId();
                    context.admin.saveIssue(module.original);
                }
            };

            module.rankSolution = function (vm, event) {
                if (module.allowSolutionRank()) {
                    module.solutionRank(Number($(event.target).data("rank")));
                    module.original.solutionRank = module.solutionRank();
					module.original.classification.id = module.classificationId();
                    context.admin.saveIssue(module.original);
                }
            };

            module.attachmentUrl = function (id) {
                return dataservice.getUrl("issue/" + module.id() + "/attachment/" + id + "/content");
            };

            module.canActivate = function () {
                return context.session.requireAccess();
            };

            module.refreshIssue = function () {
                return context.admin.getIssue(module.id(), module.hashCode)
                        .then(function (data) {
                            module.status(i18n.t("app:status." + data.status.toLowerCase()));
                            module.statusRaw(data.status.toLowerCase());
                            module.created(new Date(data.created).toLocaleDateString());
                            module.modified(new Date(data.lastModified).toLocaleDateString());
                            module.modifiedBy(data.lastModifier);
                        });
            };

            module.onLayoutChange = function( params ) {
                module.sidebarWidth( params.mobile === true ? "100%" : "25%" );
                module.issueDetailsWidth( params.mobile === true ? "100%" : "75%" );
            };

            module.deactivate = function() {
                callbacks.layoutChange.remove(module.onLayoutChange);
            };
            
            module.removeAssignedEditor = function( editor, editors ) {
                if( !editor ) { return editors; }
                var r = [];
                for( var i = 0; i < editors.length; i++ ) {                    
                   if( editors[i].userName() !== editor ) {
                       r.push(editors[i]);
                   }
                }
                return r;
            };                       
            
            module.prepareEditors = function( assignedEditor ) {
                var r = module.removeAssignedEditor( assignedEditor, module.allEditorsForSupplier);                
                var choose = {
                    niceNameWithDepartment: i18n.t("app:labels.choose"),
                    userName: assignedEditor
                };                                
                return [choose].concat(r);
            };                        

            module.activate = function (id, hashCode) {
                callbacks.layoutChange.add(module.onLayoutChange);

                module.hashCode = hashCode;

                module.token(dataservice.token());
                module.id(id);
                module.allClassifications.removeAll();
                module.parameters.removeAll();
                module.attachments.removeAll();
                module.messages.removeAll();
                module.allEditors.removeAll();
                module.editorAssigned(null);
                module.editor(null);
                                 
                     return context.businessenum.getClassifications().then(function (classifications) {
						 
						for (var k = 0; k < classifications.length; k++) {
							
							if (classifications[k].parentId) {
								classifications[k].name = i18n.t("app:issueobjects." + classifications[k].parentId );
							} else {
								classifications[k].name = i18n.t("app:issueclassifications." + classifications[k].id );
								
								if (classifications[k].name.indexOf("app:") > -1) {
									classifications[k].name = "";
								}
							}
						}
                        module.allClassifications(classifications);

                        return context.admin.getIssue(id, module.hashCode).then(function (data) {
                            if (context.session.hasIssuerAccess() && data.issuerUserName && context.session.info().userName
                                    && context.session.info().userName.toLowerCase() !== data.issuerUserName.toLowerCase()) {
                                return false; // only allow issuers that reported the current issue
                            }
                            if (context.session.hasEditorAccess() &&
                                    !context.session.hasEditorAccessToIssueById( data.supplierId )
                                    && context.session.info().userName.toLowerCase() !== data.issuerUserName.toLowerCase() ) {
                                return false; // only allow editors from the same supplier
                            }
                            return context.user.editorUsers(data).then(function (editors) {                              
                             
                            module.original = data;
                            module.allEditorsForSupplier = editors;    
                            module.allEditors(module.prepareEditors(data.editorUserName));
                                                
                            module.parameters(data.issueParameters);

                            context.attachment.getAttachmentsByIssueId(id).then(function (attachments) {
                                module.attachments(_.map(attachments, function (currentAttachment) {
                                    return {
                                        userId: currentAttachment.userId,
                                        id: currentAttachment.id,
                                        name: currentAttachment.name,
                                        url: module.attachmentUrl(currentAttachment.id),
                                        deletable : ko.computed(function() {                                                                                        
                                            var closed = module.isClosedState();                                                                                        
                                            return context.session.hasAdminAccess() || 
                                                    ( !closed && module.isCurrentUser(currentAttachment.userId) );                                            
                                        })
                                    };                                                                                                                                                 
                                }));
                            });

                            context.message.getMessagesByIssueId(id).then(function (messages) {
                                module.messages(_.map(messages, function (currentMessage) {
                                    return {
                                        name: currentMessage.authorName,
                                        date: currentMessage.createdDate,
                                        text: currentMessage.text
                                    }
                                }));
                            });							

                            module.issueRank(data.issueRank || 0);
                            module.solutionRank(data.solutionRank || 0);                            
                            module.number(data.number);
                            module.summary(data.summary); 
                            module.description(data.description);
                            module.descriptionWithoutTranslation(null);
                            
							module.isDescTranslatedEnabled(true);
							module.isRedoDescTranslatedEnabled(false);
							
							module.isCommentsTranslatedEnabled(true);
							module.isRedoCommentsTranslatedEnabled(false);
						
                            module.issueObject(i18n.t("app:issueobjects." + data.issueObject.id ));
							module.issueObjectId(data.issueObject.id);
                            module.issueType(i18n.t("app:issuetypes." + data.issueType.id ));
                            module.issueTypeId(data.issueType.id);
                            module.source(data.source.name);
                            module.classification(i18n.t("app:issueclassifications." + data.classification.id));							
							module.classificationId( data.classification.id );
                            module.softwareVersion(data.softwareVersion);
                            module.dataVersion(data.dataVersion);
                            module.status(i18n.t("app:status." + data.status.toLowerCase()));
                            module.statusRaw(data.status.toLowerCase());
                            module.created(new Date(data.created).toLocaleDateString());
                            module.modified(new Date(data.lastModified).toLocaleDateString());
                            module.modifiedBy(data.lastModifier);
                            module.issuer(data.issuerName);
                            module.issuerPhone(data.issuerPhone);
                            module.issuerPhoneLink('tel:' + data.issuerPhone);
							module.issuerUserName(data.issuerUserName);
                            module.issuerName(data.issuerName);

                            // zwar ist oben abgefangen dass ein anderer Issuer die Fehlermeldung ansehem kann, es koennte aber ein Editor sein,
                            // der eine neue ansieht, der darf die nicht bearbeiten
                            
                            if (data.editorUserName) {
                                module.hasEditor(true);
                                module.editor(data.editorUserName);                                
                                module.editorEmail(data.editorEmail);
                                module.editorPhone(data.editorPhone);
                                module.editorPhoneLink('tel:' + data.editorPhone);
                                module.editorName(data.editorNiceName);
                                module.editorAssigned(data.editorUserName);                                
                            } else {
                                module.hasEditor(false);
                            }                                                        
                            module.relatedIssue(data.relatedIssue);
                            module.issuerEmail(data.issuerEmail);
                            
                            module.issuerLang(data.issuerLang);
							module.emailLanguage( config.getLanguageName( data.issuerLang ) );
                           
                            context.supplier.getSupplierById(data.supplierId).then(function (supplier) {
                                module.supplier(supplier.name);
                            });

                            // timer, because the content should first be created
                            setTimeout(function () {
                                var mailBody = encodeURIComponent(module.prepareEmailBody());
                                module.issuerMailto(module.mailToLink(data.issuerEmail, data.editorEmail, data, mailBody));

                                if (module.hasEditor()) {
                                    module.editorMailto(module.mailToLink(data.editorEmail, data.issuerEmail, data, mailBody));
                                }
                                var shareEmailBody = encodeURIComponent(module.prepareShareEmailBody());
                                module.shareEmailLink(module.shareLink(data, shareEmailBody));
                            }, 300);

                            return true;
                        });
                    })
                    .fail(function() {
                        // console.log("fail to load issue by id: " + id);
                        presenter.toast("Ticket by id: '" + id + "' not found!", presenter.LEVEL.DANGER);
                        window.setTimeout(function() {
                            router.navigate('issues');
                        }, 1000);
                    });
                }); //for editorUsers()
            };

            module.mailToLink = function (email, cc, data, mailBody) {
                return 'mailto:' + email
                        + "?subject=" + encodeURIComponent( "[SRS] " + data.summary + " (" + data.number + ")" )
                        + "&body=" + mailBody;
            };

            module.shareLink = function (data, mailBody) {
                return 'mailto:'
                        + "?subject=" + encodeURIComponent( "[SRS] " + data.summary + " (" + data.number + ")" )
                        + "&body=" + mailBody;
            };

            module.getLink = function() {
            	return window.location.href;
            };

            module.prepareEmailBody = function () {
                var s = i18n.t("app:labels.hello") + ",\n\n";

                s += module.getLink()  + "\n";

                s += "\n\n" + i18n.t("app:labels.best_regards") + "\n";
                s += context.session.info().firstName + " " + context.session.info().lastName;
                return s;
            };

            module.prepareShareEmailBody = function () {
                var s = i18n.t("app:labels.hello") + ",\n\n";

                s += module.getLink()  + "\n";

                var fields = $(".control-label:visible,.form-control:visible,"
                        + ".form-control-static:visible", "#issueForm");

                for (var i = 0; i < fields.length; i++) {
                    var e = fields[i];
                    try {
                        if ($(e).hasClass('control-label')) {
                            s += "\n";
                            if (!$(e).hasClass('skip')) {
                               s += $(e).text() + ": ";
                            }
                        } else
                        if ($(e).hasClass('form-control')) {
                            if ($(e).prop("tagName") === 'SELECT') {
                                s += $.trim($(e).children("option").filter(":selected").text());
                            } else {
                                s += "\n" + $.trim($(e).val())+"\n";
                            }
                        } else
                        if ($(e).hasClass('form-control-static')) {
                            s += $.trim($(e).text());
                        }
                    } catch (ex) {

                    }
                }

                s += "\n";

                var labels = $('.infoMail', '#issueInfoPanel');
                for (var i = 0; i < labels.length; i++) {
                    var div = labels[i];
                    var e = $(div).find('label')[0];
                    if($(div).hasClass('scope')) {
                        s += " (" + $.trim($(e).next().text()) + ")";
                    } else {
                        s += "\n" + $(e).text() + ": " + $.trim($(e).next().text());
                    }
                }
                s += "\n\n" + i18n.t("app:labels.best_regards") + "\n";
                s += context.session.info().firstName + " " + context.session.info().lastName;
                return s;
            };

            module.save = function () {
                module.original.description = module.description();
                module.original.issueRank = module.issueRank();
                module.original.solutionRank = module.solutionRank();
                
                module.original.classification.id = module.classificationId();
				module.original.issueObject = { id: module.issueObjectId(), name: module.issueObject() };
                module.original.issueType = {id: module.issueTypeId(), name: i18n.t("app:issuetypes." + module.issueTypeId())};
                
                //module.original.editor = module.editor();
                var classifications = ko.toJS(module.allClassifications);
                for (var k = 0; k < classifications.length; k++) {
                    if (classifications[k].id === module.original.classification.id) {
                        module.original.classification.name = classifications[k].name;
                        break;
                    }
                }
                
                if (module.additionalParameters()) {
                	var adds = ko.toJS(module.additionalParameters());
					for (var d = 0; d < adds.length; d++) {
						module.parameters.push(adds[d]);
					}
					
					module.additionalParameters.removeAll();
                }
                
                module.original.issueParameters = module.parameters();
                module.original.editorUserName = null;

                context.admin.saveIssue(module.original);
            };

            module.reset = function () {
                module.description(module.original.description);
                module.classification(module.original.classification.id);
				module.isDescTranslatedEnabled(true);
            	module.isRedoDescTranslatedEnabled(false);
            };

            module.deleteRelatedIssue = function () {
                var options = [{text: i18n.t("app:labels.ok"), value: "ok"}, {
                        text: i18n.t("app:labels.cancel"),
                        value: "cancel"
                    }];
                dialog.showMessage(i18n.t("app:messages.delete_related_issue"),
                                    i18n.t("app:titles.delete_related_issue"), options, false, {"class":"appMessageBox modal-content modal-content-xs"})
                        .then(function (result) {
                            if (result === "ok") {
                                module.original.relatedIssue = null;
                                module.relatedIssue(null);
								module.original.classification.id = module.classificationId();
                                context.admin.saveIssue(module.original);
                            }
                        });
            };

            module.updateRelatedIssue = function () {
                var relatedIssue = module.relatedIssue;
                modal.IssuesModal.show(relatedIssue, module.id())
                        .then(function () {
                            var result = relatedIssue();
                            if (result && (module.original.relatedIssue == null || result.id !== module.original.relatedIssue.id)) {
                                module.original.relatedIssue = relatedIssue();
								module.original.classification.id = module.classificationId();
                                context.admin.saveIssue(module.original);
                            }
                        });
            };

            module.deleteAttachment = function (attachment) {
                //presenter.toast(i18n.t("app:messages.upload_failed"), presenter.LEVEL.DANGER);
                //presenter.toast("You are not the owner of the attachment and therefore not allowed to delete it", presenter.LEVEL.DANGER, msg);
                var userId = attachment.userId;
                var currentUser = context.session.info().userName;
                if (context.session.hasEditorAccess() || context.session.hasIssuerAccess()) {
                    context.attachment.deleteAttachment(module.id(), attachment.id, userId, currentUser).done(function (id) {
                        if (id != null) {
                            module.attachments.remove(attachment);
                            module.attachments.valueHasMutated();
                        }
                    });
                } else if (context.session.hasAdminAccess()) {
                    context.attachment.deleteAttachment(module.id(), attachment.id, currentUser, currentUser).done(function (id) {
                        module.attachments.remove(attachment);
                        module.attachments.valueHasMutated();

                    });
                }
            };

            module.assign = function () {
                module.original.issueRank = module.issueRank();
                module.original.solutionRank = module.solutionRank();
                module.original.classification.id = module.classification();
                var classifications = ko.toJS(module.allClassifications);
                for (var k = 0; k < classifications.length; k++) {
                    if (classifications[k].id === module.original.classification.id) {
                        module.original.classification.name = classifications[k].name;
                        break;
                    }
                }
                module.original.editorUserName = module.editor;
                context.admin.assignIssue(module.original).done(function (issue) {
                    module.allEditors([]);
                    module.allEditors(module.prepareEditors(issue.editorUserName));                    
                    module.status(i18n.t("app:status." + issue.status.toLowerCase()));
                    module.statusRaw(issue.status.toLowerCase());
                    module.hasEditor(true);
                    module.editor(issue.editorUserName);
                    module.editorEmail(issue.editorEmail);
                    module.editorPhone(issue.editorPhone);
                    module.editorPhoneLink('tel:' + issue.editorPhone);
                    module.editorName(issue.editorNiceName);
                    module.editorAssigned(issue.editorUserName);
                });
            };

            module.isLastMessageMyMessage = ko.computed(function() {
                if (module.messages() != null && module.messages().length > 0) {
                    var k = 0; // 0 because the Last Message is on Top
                    if (module.messages()[k].name == module.editorName() || 
                    	module.messages()[k].name == module.issuerName() ||
                    	module.messages()[k].name == context.session.info().niceName) {
                        return true;
                    }
                }
                return false;
            }, module);
			
			module.paramValueChanged = function() {				
				if (module.additionalParameters()) {
                	var adds = ko.toJS(module.additionalParameters());
                	var filled = true;
                	
					for (var d = 0; d < adds.length; d++) {
						if (adds[d].value.length === 0) {
							filled = false;
						}
					}
					
					module.saveAllowed(filled);
                }
			};
			
			module.classificationId.subscribe(function (latest) {
				
				if (!module.allowParameterChange()) {
					return;
				}
				
				 switch (module.classificationId()) {
					case "04":
					case "05":
					case "06":
					case "07":
					case "08":
					case "11":
					case "19":
					case "31":
					case "32":
					case "33":
					case "34":
						module.issueObjectId("D01"); // IAM Article
						break;
					case "18":
					case "28":
						module.issueObjectId("D11"); // GA
						break;
					case "20":
					case "21":
					case "22":
					case "29":
					case "30":
						module.issueObjectId("D12"); // OE Article
						break;
					case "39":
					case "40":
						module.issueObjectId("D14"); // Maintenance Service
						break;
					case "36":
					case "37":
						module.issueObjectId("D16"); // Repair Manual
						break;
					case "09":
					
						// Others / RMI - Missing RMI: D10, D13, D15, D17, D18
						if (module.original.issueObject.id === "D10" ||
							module.original.issueObject.id === "D13" ||
							module.original.issueObject.id === "D15" ||
							module.original.issueObject.id === "D17" ||
							module.original.issueObject.id === "D18") {
								
							module.issueObjectId(module.original.issueObject.id);
						} else {
							module.issueObjectId("U"); // Others
						}
						
						break;
					case "14":
						// Body type / Body type linkage
						if (module.original.issueObject.id === "D08" ||
							module.original.issueObject.id === "D09") {
							
							module.issueObjectId(module.original.issueObject.id);
						} else {
							module.issueObjectId("D08"); // Body type
						}
						
						break;
					default:
					
						// Vehicle / Bike / Axle / Engine / Body type
						// Missing F/A/M/BT: D02, D03, D04, D05, D06, D07
						if (module.original.issueObject.id === "D02" ||
							module.original.issueObject.id === "D03" ||
							module.original.issueObject.id === "D04" ||
							module.original.issueObject.id === "D05" ||
							module.original.issueObject.id === "D06" ||
							module.original.issueObject.id === "D07" ||
							module.original.issueObject.id === "D09") {
							
							module.issueObjectId(module.original.issueObject.id);
						} else {
							module.issueObjectId("D02"); // Vehicle / Bike
						}	

						break;
					}
					
					module.issueObject(i18n.t("app:issueobjects." + module.issueObjectId() ));
						
					module.additionalParameters.removeAll();
					context.businessenum.getIssueObjectById(module.issueObjectId())
						.then(function (issueObject) {
							
							var objectParams = ko.toJS(issueObject.parameters);
									
							for (var b = 0; b < objectParams.length; b++) {
								if (objectParams[b].mandatory === true) {										
									var existingParams = ko.toJS(module.parameters());
									var found = false;
									for (var f = 0; f < existingParams.length; f++) {
										if (objectParams[b].tag === existingParams[f].parameterId) {
											found = true;
											break;
										}
									}
									
									if (!found) {
										module.additionalParameters.push({ 
											parameterId: objectParams[b].tag,
											value: "",
											order: objectParams[b].order
										});
									}
								}
							}
							
							module.allowSave();
						});
			}, module);

            module.closeLabel = ko.computed(function() {
                if( module.isLastMessageMyMessage() ) {
                    return i18n.t("app:labels.close");
                }
                return i18n.t("app:labels.reply_and_close");
            }, module);

            module.close = function () {
                if (module.isLastMessageMyMessage()) {
                	
                	if (module.isTranslateDescVisible()) {
                		module.redoDescription();
                		
                		if (module.description() != null && module.description() != '') {
                			module.original.description = module.description();
                		}
                	}
                	
                    var issueToClose = module.original;
                    context.admin.closeIssue(issueToClose).done(function (issue) {

                        module.status(i18n.t("app:status." + issue.status.toLowerCase()));
                        module.statusRaw(issue.status.toLowerCase());
                    });
                }
                else
                {
                    //presenter.toast(i18n.t("app:validation.comment_empty"), presenter.LEVEL.DANGER);
                    module.newMessage(true);
                }
            };

            module.reject = function () {
                modal.MessageModal.show({issueId: module.id(), userInfo: context.session.info(), close: false, issuerLang: module.issuerLang(), modal: modal})
                        .then(function (message) {
                            if (message) {
                                context.admin.rejectIssue(module.id())
                                        .done(function (issue) {
                                            module.status(i18n.t("app:status." + issue.status.toLowerCase()));
                                            module.statusRaw(issue.status.toLowerCase());
                                            module.messages.unshift({
                                                name: message.authorName,
                                                date: message.createdDate,
                                                text: message.text
                                            });
                                        });
                            }
                        });
            };

            module.accept = function () {
                context.admin.acceptIssue(module.id()).done(function (issue) {
                    module.status(i18n.t("app:status." + issue.status.toLowerCase()));
                    module.statusRaw(issue.status.toLowerCase());
                });
            };
            
            module.translateDescription = function () {
            	context.admin.deeplTranslation( {
            		text: module.description(), 
            		language: context.session.info().lang } 
            	).then(function (message) {            		
            	
            		if (message && message != "" && message.text && message.text != "") {
            			module.descriptionWithoutTranslation(module.description());
            			module.description(message.text);
            			module.isDescTranslatedEnabled(false);
            			module.isRedoDescTranslatedEnabled(true);
            		}
            	});
            };
            
            module.redoDescription = function () {
            	if (module.descriptionWithoutTranslation() != null && module.descriptionWithoutTranslation() != '') {
            		module.description(module.descriptionWithoutTranslation());
            		module.isDescTranslatedEnabled(true);
            		module.isRedoDescTranslatedEnabled(false);
            	}
            };
            
            module.translateAllComments = function () {
            	
				for (var m = 0; m < module.messages().length; m++) {
                    context.admin.deeplTranslation( {
                    	text: module.messages()[m].text, 
                    	language: context.session.info().lang,
                    	index: m }
                    ).then(function (message) { 
                    	module.messages(_.map(module.messages(), function (currentMessage, index) {
                    		return {
                    			name: currentMessage.name,
                    			date: currentMessage.date,
                    			text: (index == message.index && message.text.length > 0) ? message.text : currentMessage.text
                    		}
                    	}));
					});
                }
				
				module.isCommentsTranslatedEnabled(false);
            	module.isRedoCommentsTranslatedEnabled(true);
            }
            
            module.redoComments = function () {
				
				context.message.getMessagesByIssueId(module.id()).then(function (messages) {
					module.messages(_.map(messages, function (currentMessage) {
						return {
							name: currentMessage.authorName,
							date: currentMessage.createdDate,
							text: currentMessage.text
						}
					}));
				});
				
				module.isCommentsTranslatedEnabled(true);
            	module.isRedoCommentsTranslatedEnabled(false);
            }
            
            module.showTranslateInfoDesc = function () {
            	modal.TranslateInfoModal.show({title: i18n.t("app:labels.translate_info_header"), message: i18n.t("app:labels.translate_info_description")});
            }

			module.showTranslateInfoComments = function () {
            	modal.TranslateInfoModal.show({title: i18n.t("app:labels.translate_info_header"), message: i18n.t("app:labels.translate_info_messages")});
            }
			
            module.reactivate = function () {
                context.admin.reactivateIssue(module.id()).done(function (issue) {
                    module.status(i18n.t("app:status." + issue.status.toLowerCase()));
                    module.statusRaw(issue.status.toLowerCase());
                });
            };

            module.newMessage = function (close) {
                modal.MessageModal.show({issueId: module.id(), userInfo: context.session.info(), close: close, issuerLang: module.issuerLang(), modal: modal})
                        .then(function (message) {
                            if (message) {
                                module.refreshIssue();
                                module.messages.unshift({
                                    name: message.authorName,
                                    date: message.createdDate,
                                    text: message.text
                                });
                                if( close === true ) {
                                    module.close();
                                }
                            }
                        });
            };           
            
            module.openAttachment = function (attachment) {				
				var params = {
	                token: module.token()
	            }; 
	            
	            var form = document.createElement("form");
			  	form.setAttribute("method", "post");
			  	form.setAttribute("action", attachment.url);
			  	form.setAttribute("target","_blank");  
			  	for (var i in params) {
			    	if (params.hasOwnProperty(i)) {
				      var input = document.createElement('input');
				      input.type = 'hidden';
				      input.name = i;
				      input.value = params[i];
				      form.appendChild(input);
			    	}
			  	}              
			  	document.body.appendChild(form);  
			 	form.submit();                 
			  	document.body.removeChild(form);
	        };

            return module;
        });
