define(
    'viewmodels/issues',[
        'jquery',
        'knockout',
        'contexts/context',
        'models/model',
        'plugins/router',
        'i18next',
        'base/callbacks'
    ],
    function ($, ko, context, dataModel, router, i18n, callbacks) {
        "use strict";

        var module = {};

        module.pageControl = new dataModel.Pagination({
            limit: 50,
            filterActive: true,
            filterSupplier: 1,
            loader: context.admin.getIssues
        });

        module.tableDataLoaded = ko.computed(function() {
            module.pageControl.list();
            callbacks.tableDataLoaded.fire();
        }, module ).extend({rateLimit: 300});

        module.orderBy = function(params) {
            module.pageControl.orderBy( params.order, params.asc );
        };

        module.attached = function () {
            $(window).trigger("resize");
        };

        module.deactivate = function () {
            context.pageControl(dataModel.Pagination.Nil);
            callbacks.sortClicked.remove(module.orderBy);
        };

        module.canActivate = function () {
            return context.session.requireAccess();
        };

        module.activate = function () {
            $(window).trigger("resize");
            module.pageControl.load();
            context.pageControl(module.pageControl);
            callbacks.sortClicked.add(module.orderBy);
            return true;
        };

        module.goToIssue = function (issue) {
            router.navigate("issues/" + issue.id());
        };

        return module;
    });
