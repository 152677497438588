define('contexts/context',
        [
            'jquery',
            'plugins/router',
            'plugins/dialog',
            'knockout',
            'models/model',
            'contexts/admin',
            'contexts/session',
            'contexts/supplier',
            'contexts/user',
            'contexts/businessenum',
            'contexts/attachment',
            'contexts/message'
        ],
        function($, router, dialog, ko, model, admin, session, supplier, user, classification, attachment, message) {
            "use strict";

            var module = {
                admin: admin,
                session: session,
                supplier: supplier,
                user: user,
                businessenum: classification,
                attachment: attachment,
                message: message
            };

            module.pageControl = ko.observable(model.Pagination.Nil);
            
            module.isDialogMovable = function() {
                return $('.modal-content:visible:last').find('.close').length > 0 ||
                        $('.modal-content:visible:last').hasClass('movable');
            };
                        
            module.dialogCounter = 0;
            module.intervalModalsRunCounter = 0;
            module.intervalIdModals = null;
            
            module.onOpenPopup = ko.computed(function() {
                var open = dialog.isOpen();
                
                if (open) {
                    
                    module.intervalModalsRunCounter = 0;
                    module.intervalIdModals = setInterval(function() {
                        module.intervalModalsRunCounter++;
                        if (module.intervalModalsRunCounter > 1000) {
                            clearInterval(module.intervalIdModals);
                        }
                        if ($('.modal-content:visible').length !== module.dialogCounter) {
                            module.dialogCounter = $('.modal-content:visible').length;
                            clearInterval(module.intervalIdModals);

                            if (module.isDialogMovable()) {
                                var dial = $('.modal-content:visible:last');
                                dial.hide();
                                setTimeout(function() {
                                    dial.attr('style', 'margin: 0 !important;');                                    
                                    dial.attr('move', 'on');
                                    dial.center();
                                    dial.show();
                                }, 300);
                            }
                        }
                    }, 5);
                } else {
                    setTimeout(function() {
                        module.dialogCounter = $('.modal-content:visible').length;
                    }, 500);
                }
            });

            return module;
        });

