define('base/forms',
    [
        'knockout',
        'underscore'
    ],
    function (ko, _) {
        "use strict";

        var module = {};                
        
        module.checkForm = function (observables, isSubmitted) {
            var time = new Date().getTime();
            _.each(observables, function (item) {
                if (_.isFunction(item.isSubmitted)) {
                    item.isSubmitted(isSubmitted);
                    if (_.isFunction(item.lastValue)) {
                        item.lastSubmitTime(time);
                    }
                }                
            });
            return _.every(observables, function (item) {
                return _.isFunction(item.hasError) ? !item.hasError() : true;
            });
        };

        module.serialize = function (observables, target) {
            return _.extend(_.clone(target || {}), _.mapObject(ko.unwrap(observables), function (item) {
                return ko.unwrap(item);
            }));
        };

        module.updateFromDto = function (observables, dto) {
            _.each(dto, function (val, key) {
                if (observables.hasOwnProperty(key)) {
                    if (_.isFunction(observables[key])) {
                        observables[key](val);
                    } else {
                        observables[key] = val;
                    }
                }
            });
            return observables;
        };


        return module;
    });
