define('dataservices/dataservice',
    [
        'amplify',
        'knockout',
        'dataservices/config',
        'dataservices/issue',
        'dataservices/supplier',
        'dataservices/user',
        'dataservices/businessenum',
        'dataservices/attachment',
        'dataservices/message',
        "base/store",
        'base/presenter'
    ],
    function (amplify, ko, config, issue, supplier, user, businessenum, attachment, message, store, presenter) {
        "use strict";

        var API_PREFIX = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? "" : "webapi/",
            STORE_SESSION = "SRS-SESSION";

        var module = {
            token: ko.observable(""),
            lang: ko.observable("en"),
            baseUrl: ko.observable(""),
            config: config,
            issue: issue,
            supplier: supplier,
            user: user,
            businessenum: businessenum,
            attachment: attachment,
            message: message
        };

        function modifyUrl(url) {
            if (module.baseUrl()) {
                return module.baseUrl() + "/" + url;
            }
            return url;
        }

        function upload($el, url, skipReset) {
            return $.Deferred(function (def) {
                $el.ajaxSubmit({
                    clearForm: false,
                    resetForm: false,
                    contentType: "multipart/form-data; charset=utf-8",
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader("X-CustomToken", module.token());
                    },
                    beforeSubmit: function (arr, $form, options) {
                        presenter.startBlocking("upload", "services.upload"); // 
                        return true; // OK to submit
                    },
                    uploadProgress: function (event, position, total, percentComplete) {
                        // console.log("Progress " + position + ", " + total + ", " + percentComplete);
                    },
                    success: function (responseText, statusText, xhr, $form) {
                        presenter.stopBlocking("upload");
                        if (!skipReset) {
                            $el.resetForm();
                        }
                        def.resolve({responseText: responseText});
                    },
                    error: function (msg) {
                        presenter.stopBlocking("upload");
                        if (!skipReset) {
                            $el.resetForm();
                        }
                        def.reject({message: msg});
                    },
                    dataType: null,
                    url: url
                });
            }).promise();
        }

        module.getUrl = function (url) {
            return modifyUrl(url);
        };

        module.uploadDatafile = function ($el, id) {
            return upload($el, modifyUrl("issue/" + id + "/attachment"));
        };

        module.uploadSupplierList = function ($el) {
            return upload($el, modifyUrl("supplier/upload"), true);
        };

        module.importSupplierList = function ($el) {
            return upload($el, modifyUrl("supplier/import"));
        };

        module.uploadIssueList = function ($el) {
            return upload($el, modifyUrl("issue/upload"), true);
        };

        module.importIssueList = function ($el) {
            return upload($el, modifyUrl("issue/import"));
        };

        module.registerServices = function () {
            config.registerServices();
            issue.registerServices();
            supplier.registerServices();
            user.registerServices();
            businessenum.registerServices();
            attachment.registerServices();
            message.registerServices();
        };

        (function () {
            amplify.request.decoders._default = function (data, status, xhr, success, error) {
                if (status === "success" || xhr.status === 202 ) {
                    success(data, status);
                } else if (status === "fail" || status === "error") {
                    try {
                        if (xhr.status === 401) {
                            status = "unauthorized";
                        }
                        error(JSON.parse(xhr.responseText), status);
                    } catch (er) {
                        error(xhr.responseText, status);
                    }
                }
            };

            amplify.subscribe("request.complete", function (settings, data, status) {
                if (status === "unauthorized" && module.token()) {
                    store.clear(STORE_SESSION);
                    module.token("");
                    window.location.reload(true);
                }
            });

            amplify.subscribe("request.ajax.preprocess", function (defnSettings, settings, ajaxSettings) {
                ajaxSettings.url = modifyUrl(ajaxSettings.url);
                ajaxSettings.dataType = ajaxSettings.dataType || "json";
                if (ajaxSettings.type !== 'GET') {
                    ajaxSettings.url = ajaxSettings.url + "?lang=" + module.lang();
                    ajaxSettings.data = JSON.stringify(ajaxSettings.data);
                    ajaxSettings.contentType = "application/json; charset=utf-8";
                } else {
                    ajaxSettings.data.lang = module.lang();
                    //ajaxSettings.data.sessionId = module.token(); // only used to prevent IE from caching
                }
            });


            amplify.subscribe("request.before.ajax", function (resource, settings, ajaxSettings, xhr) {
                if (module.token()) {
                    xhr.setRequestHeader("X-CustomToken", module.token());
                }
                var identifier = ajaxSettings.url;
                presenter.startBlocking(identifier, "services." + ajaxSettings.resourceId);
                ajaxSettings.complete = function () {
                    presenter.stopBlocking(identifier);
                };
            });

        }());

        return module;
    });
