define('base/config',
        [
            'jquery',
            'toastr',
            'moment',
            'numeral',
            'numeral-langs'
        ],
        function ($, toastr, moment, numeral) {
            "use strict";

            var language = window.navigator.userLanguage || window.navigator.language || 'en-US';

            var module = {
                baseUrl: (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? "http://localhost:8070" : "",
                locale: language,
                i18NOptions: {
                    detectFromHeaders: false,
                    lng: language,
                    fallbackLang: 'en',
                    ns: 'app',
                    resGetPath: 'locales/__lng__/__ns__.json?v=2.5.5',
                    useCookie: false,
                    useLocalStorage: false,
                    localStorageExpirationTime: 3600000 // in ms, default 1 week
                },
                storeExpirationMs: 604800000, // 7 * 24 * 60 * 60 * 1000,
                toastrOptions: {
                    "closeButton": false,
                    "debug": false,
                    "positionClass": "toast-top-left",
                    "onclick": null,
                    "showDuration": "300",
                    "hideDuration": "1000",
                    "timeOut": "5000",
                    "extendedTimeOut": "1000",
                    "showEasing": "swing",
                    "hideEasing": "linear",
                    "showMethod": "fadeIn",
                    "hideMethod": "fadeOut"
                },
                states: [
                    {id: 0, name: "new"},
                    {id: 1, name: "accepted"},
                    {id: 2, name: "rejected"},
                    {id: 3, name: "assigned"},
                    {id: 4, name: "feedback"},
                    {id: 5, name: "answered"},
                    {id: 6, name: "closed"},
                    {id: 7, name: "reactivated"}
                ],
                languages: [
                    {key: "de", name: "Deutsch"},
                    {key: "nl", name: "Nederlands"},
                    {key: "en", name: "English"},
                    {key: "es", name: "Español"},
                    {key: "fr", name: "Français"},
                    {key: "it", name: "Italiano"},
                    {key: "tr", name: "Türkçe"},
                    {key: "zh", name: "中国"}
                ],
                useMocks: false
            };

            module.getAvailableLanguage = function (lang) {
                for (var i = 0; i < module.languages.length; i++) {
                    if (module.languages[i].key === lang) {
                        return lang;
                    }
                }
                return "en";
            };
            
            module.getLanguageName = function (lang) {
            	for (var i = 0; i < module.languages.length; i++) {
            		if(module.languages[i].key === lang) {
            			return module.languages[i].name;
            		}
            	}
            	return module.languages[2].name;
            }

            /**
             * Initialization
             */
            module.init = function () {
                module.locale = module.locale.substr(0, 2);
                toastr.options = module.toastrOptions;
                if (module.useMocks) {
                    toastr.warning(i18n.t("app:messages.usingmocks"));
                }

                try {
                    moment.locale(module.locale);
                    numeral.locale(module.locale);
                } catch (e) {
                    moment.locale('en');
                    numeral.locale('en');
                }
            };

            module.init();

            return module;
        });
