define(
        'viewmodels/modals/message_create',[
            "plugins/dialog",
            "knockout",
            "base/forms",
            "contexts/context",
            "i18next"
        ],
        function (dialog, ko, forms, context, i18n) {
            "use strict";
			
            var MessageModal = function (params) {
                var self = this;

                self.issueId = params.issueId || 0;
                self.userInfo = params.userInfo || {};
                self.okLabel = i18n.t(params.close === true ? "app:labels.close_ticket" : "app:labels.send");
				
				self.editMessageForm = {
                    message: ko.observable().extend({
                        validate: {name: "message", type: "textarea", rows: 5, checks: ["required"]}
                    })
                };
               
                self.isTranslateVisible = (params.issuerLang !== context.session.info().lang) ? true : false;
				
				self.translateComment = function () {
					context.admin.deeplTranslation( {
						text: this.editMessageForm.message(),
						language: params.issuerLang
					}).then( function (message) {
						self.editMessageForm.message( message.text );
					});
				};
				
				self.showTranslateInfo = function () {
					params.modal.TranslateInfoModal.show({title: i18n.t("app:labels.translate_info_header"), message: i18n.t("app:labels.translate_info_message_input")});
				}

                self.cancel = function () {
                    dialog.close(self, null);
                };
            };

            MessageModal.prototype.ok = function () {
                var self = this,
                        messageDto = {};

                if (forms.checkForm(this.editMessageForm, true)) {
                    messageDto = {
                        text: this.editMessageForm.message(),
                        id: 0,
                        authorName: this.userInfo.niceName,
                        authorEmail: this.userInfo.email,
                        createdDate: new Date()
                    };
                    context.message.saveMessage(this.issueId, messageDto)
                            .then(function () {
                                dialog.close(self, messageDto);
                            });
                }
            };

            MessageModal.show = function (params) {
                return dialog.show(new MessageModal(params));
            };

            return MessageModal;
        });
