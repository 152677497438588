requirejs.config({
    paths: {
        'amplify': '../libs/amplify/lib/amplify',
        'amplify-request-deferred': '../libs/amplify-request-deferred/lib/amplify.request.deferred',
        'bootstrap': '../libs/bootstrap/dist/js/bootstrap',
        'bootstrap-datepicker': '../libs/bootstrap-datepicker/dist/js/bootstrap-datepicker.min',
        'chosen': '../libs/chosen/chosen.jquery.min',
        'durandal': '../libs/durandal/js',
        'i18next': '../libs/i18next/i18next.amd.withJQuery.min',
        'jquery': '../libs/jquery/dist/jquery.min',
        'jquery-form': '../libs/jquery-form/dist/jquery.form.min',
        'json': '../libs/requirejs-plugins/src/json',
        'knockout': '../libs/knockout/dist/knockout',
        'knockout-delegatedevents': '../libs/knockout-delegatedEvents/build/knockout-delegatedEvents',
        'moment': '../libs/moment/min/moment-with-locales.min',
        'numeral': '../libs/numeral/min/numeral.min',
        'numeral-langs': '../libs/numeral/min/locales.min',
        'plugins': '../libs/durandal/js/plugins',
        'text': '../libs/requirejs-text/text',
        'toastr': '../libs/toastr/toastr',
        'transitions': '../libs/durandal/js/transitions',
        'underscore': '../libs/underscore/underscore-min'
    },
    shim: {        
        'amplify': {
            deps: ['jquery'],
            exports: 'amplify'
        },        
        'amplify-request-deferred': {
            deps: ['amplify', 'jquery']
        },
        'bootstrap': {
            deps: ['jquery'],
            exports: 'jQuery'
        },
        'bootstrap-datepicker': {
            deps: ['jquery'],
            exports: 'jQuery'
        },
        'chosen': {
            deps: ['jquery'],
            exports: 'jQuery'
        },
        'knockout': {
            exports: ['ko']
        },
        'knockout-delegatedevents': {
            deps: ['knockout']
        },
        'numeral-langs': {
            deps: ['numeral']
        }
    }
});

define('main',[
    'durandal/system',
    'durandal/app',
    'durandal/viewLocator',
    'durandal/binder',
    'base/config',
    'dataservices/dataservice',
    'datamocks/datamock',
    'contexts/context',
    'jquery',    
    'i18next',
    'base/store',
    // init dependency-free helpers
    'jquery-form',
    'bootstrap',
    'bootstrap-datepicker',
    'chosen',
    'knockout-delegatedevents',
    'amplify-request-deferred',
    'base/customPlugins',
    'base/koExtenders',
    'base/koBindings',
    'base/formBindings'
], function (system, app, viewLocator, binder, config, dataservice, datamock, context, $, i18n, store) {
    "use strict";

    window.onerror = function (message, file, line) {
        var msg = "ERROR " + message + " (" + file + " line " + line + ")\n";
        console.log(msg);
        //>>excludeStart("build", true);
        window.alert(msg);
        //>>excludeEnd("build");
        return true;
    };

    if (config.useMocks) {
        datamock.registerServices();
    } else {
        dataservice.registerServices();
    }
    dataservice.baseUrl(config.baseUrl || "");
    dataservice.lang(config.locale);

    //>>excludeStart("build", true);
    system.debug(false);
    //>>excludeEnd("build");
    system.debug(false);


    app.configurePlugins({
        router: true,
        dialog: true,
        observable: true,
        widget: {
            kinds: []
        }
    });

    app.start()
            .always(function (success) {
                var lang = store.fetch(store.KEYS.USER_LANGUAGE);
                if (lang) {
                    config.i18NOptions.lng = lang;
                    config.locale = lang;
                    config.init();
                }
                i18n.init(config.i18NOptions, function () {
                    app.title = i18n.t("app:titles.app");
                    viewLocator.useConvention();
                    binder.binding = function (obj, view) {
                        $(view).i18n();
                    };
                    app.setRoot('viewmodels/shell', 'entrance');
                });
                return true;
            });




});
