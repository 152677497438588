define('datamocks/supplier',
    [
        'amplify'
    ],
    function (amplify) {
        "use strict";

        var module = {};

        module.registerServices = function () {

            amplify.request.define('get_all_suppliers', function (settings) {
                settings.success([]); // TODO: provide mock implementation
            });

            amplify.request.define('get_supplier_by_id', function (settings) {
                settings.success({}); // TODO: provide mock implementation
            });

            amplify.request.define('get_supplier_users', function (settings) {
                settings.success([]); // TODO: provide mock implementation
            });

        };

        return module;
    });
