define('contexts/businessenum',
    [
        'knockout',
        'dataservices/dataservice',
        'base/presenter'
    ],
    function (ko, dataservice, presenter) {
        "use strict";

        var module = {};

        module.getClassifications = function () {
            return dataservice.businessenum.getClassifications()
                .then(function (data) {
					// presenter.toast("classifications " + data[0].name, presenter.LEVEL.SUCCESS);
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_classifications_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getClassificationById = function (id) {
            return dataservice.businessenum.getClassificationById(id)
                .then(function (data) {
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_classifications_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getIssueObjects = function () {
            return dataservice.businessenum.getIssueObjects()
                .then(function (data) {
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_issueobject_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getIssueObjectById = function (id) {
            return dataservice.businessenum.getIssueObjectById(id)
                .then(function (data) {
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_issueobject_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getIssueTypes = function () {
            return dataservice.businessenum.getIssueTypes()
                .then(function (data) {
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_issuetypes_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getIssueTypeById = function (id) {
            return dataservice.businessenum.getIssueTypeById(id)
                .then(function (data) {
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_issuetypes_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getSources = function () {
            return dataservice.businessenum.getSources()
                .then(function (data) {
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_issuesources_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        module.getSourceById = function (id) {
            return dataservice.businessenum.getSourceById(id)
                .then(function (data) {
                    return data;
                })
                .fail(function (msg) {
                    presenter.toast("app:services.get_issuesources_failed", presenter.LEVEL.DANGER, msg);
                    return msg;
                });
        };

        return module;
    });
